
import { Component, Injector, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MiscUtil } from '../../../util/misc.util';

 
import { DynformControl } from '../../../model/dymform-control';

import { inject } from '@angular/core';

import { LibModulesWolcModule } from '../../../components/lib-modules-wolc.module';
import { BooktechAppService } from "../../../services/booktech-app.service";
import { BtSelectGuestsComponent } from '../select-guests/select-guests.component';


@Component({
  selector: 'btlib-pw-period-guests',
  standalone: true,
  imports: [ LibModulesWolcModule, BtSelectGuestsComponent ],
  templateUrl: './pw-period-guests.component.html',
  styleUrl: './pw-period-guests.component.scss'
})
export class BtPwPeriodGuestsComponent {
  bas = inject(BooktechAppService);

  // route:ActivatedRoute;

  views:any = {
    default: {
      grid: {
        date:     { xs: 24, sm: 24, md: 24, lg: 24, xl: 24,  xxl: 24,  gutter: 16 },
        guests:   { xs: 24, sm: 24, md: 24, lg: 24, xl: 24,  xxl: 24,  gutter: 16 },
      },
      size: "default"
    },
    fullWidth: {
      grid: {
        date:     { xs: 24, sm: 8, md: 8, lg: 8, xl: 8,  xxl: 8,  gutter: 16 },
        guests:   { xs: 24, sm: 8, md: 8, lg: 8, xl: 8,  xxl: 8,  gutter: 16 },
      },
      size: "large"
    }
  };
  view = this.views.default;

  
  @Input() pw:any;
  @Input() opts:any = { };
  @Output() change = new EventEmitter<any>();

  options:any = {
    time: {

    },
    date: {

    },
    misc: {

    }
  }

  // rangeOptions:any = {
  //   inRangeInvalid: false
  // }
  
  
  dateInvalid:Date[] = [];

  pwInput:any = undefined;

  timegrid:any = {
    visible: false,
    label: 'app.cbapi5.common.selectTime',
    validStart: [
      // { start: '12:00', end: '13:59', recurring: { repeat: 'daily' } } // Trekke fra ett minutt på end
    ],
    validEnd: [
      // { start: '12:01', end: '14:00', recurring: { repeat: 'daily' } } // Legge til ett minutt på start

    ],

  }

  constructor() {
    // this.route = inject(ActivatedRoute);
    // this.bas.us.checkActivatedRoute(this.route.snapshot);

    // let qps = this.route.snapshot.queryParams; //periodAsRange
    // if(this.bas.envtest) console.log("BtPwPeriodGuestsComponent, qps: ", qps);



  }

  ngOnInit(): void {
    let opts = this.opts;
    if (opts.viewName) {
      this.view = this.views[opts.viewName];
    }

    if(this.bas.envtest) console.log("BtPwPeriodGuestsComponent, pw: ", this.pw);
    // this.postChange();

    if (this.bas.ds.findCid('pw-period-guests.ngOnInit') === "skaperverkettonsberg") { //TODO
      this.options.date = {
        inRangeInvalid: true,
        // rangeEndInvalid: false
      }
      
    }


    let pw = this.pw; 
    if (pw.productObj.useTimegrid) {
      let tg = this.timegrid;
      tg.start = this.bas.ui.dateParseIso(pw.startAsIso8601);
      tg.end =  this.bas.ui.dateParseIso(pw.endAsIso8601);


      this.onTimegridChange('start', { valueText: pw.startTimeAsString })
      
    }


    // let tg = this.timegrid;
    // if (tg.startTimeAsString == undefined) {
    //   tg.star = item.startTimeAsString;
    //   tg.end = item.endTimeAsString;
    //   tg.label = tg.startTimeAsString + " - " + tg.endTimeAsString;

      
    // }

  }



  timegridVisibleChange(event:any) {
    if(this.bas.envtest) console.log("BtPwPeriodGuestsComponent, timegridVisibleChange: " + event);

    let pw = this.pw;
    let tg = this.timegrid;

    if (event) {



      let startTime = this.bas.ui.timeFormat(tg.start);
      let endTime = this.bas.ui.timeFormat(tg.end);
    

      let ti = pw.timeItemsAvailable && pw.timeItemsAvailable[0];
      // if(this.bas.envtest) console.log("BtPwPeriodGuestsComponent, timegridVisibleChange, ti: ", ti);

      let validStart = [];
      let validEnd = [];

      let prev:any = false;
      let startItem:any = false;
      let notAvailable = false; 
      for (let item of ti.items || []) {

        // if(this.bas.envtest) console.log("BtPwPeriodGuestsComponent, timegridVisibleChange, item: ", item);


        if (!startItem && item.startTimeAsString == startTime) startItem = item;

        let endStart = this.bas.ui.dateParse(item.startAsString);
        // endStart = this.bas.ui.dateAdd(endStart, { minutes: +1 });
        let endStartAsString = this.bas.ui.timeFormat(endStart);

        let corrEnd = this.bas.ui.dateParse(item.endAsString);
        corrEnd = this.bas.ui.dateAdd(corrEnd, { minutes: -1 });
        let corrEndAsString = this.bas.ui.timeFormat(corrEnd);

        let isLast = item.endAsString === ti.endAsString;

        let isAvailable = item.available > 0;
        if (isAvailable) {
         
          validStart.push({ start: item.startTimeAsString, end: corrEndAsString, recurring: { repeat: 'daily' } });
          

          
          if (!notAvailable && ((startTime && item.startTimeAsString > startTime) || isLast)) {
            // if(this.bas.envtest) console.log("BtPwPeriodGuestsComponent, timegridVisibleChange, validEnd, startTime: "+startTime+", item: ", item);

            

            if(startTime && item.startTimeAsString > startTime) validEnd.push({ start: endStartAsString, end: corrEndAsString, recurring: { repeat: 'daily' } });

            if (isLast) validEnd.push({ start: item.endAsString, end: item.endAsString, recurring: { repeat: 'daily' } });
          } else {
            // if(this.bas.envtest) console.log("BtPwPeriodGuestsComponent, timegridVisibleChange, !validEnd, startTime: "+startTime+", item: ", item);
          }
       


  
        } else {
          if(startItem) notAvailable = true; 

          if (prev && prev.available > 0 && (startTime && item.startTimeAsString > startTime)) {
            if(this.bas.envtest) console.log("BtPwPeriodGuestsComponent, timegridVisibleChange, validEnd-prevAvil, startTime: "+startTime+", item: ", item, ", prev: ", prev);

            validEnd.push({ start: endStartAsString, end: corrEndAsString, recurring: { repeat: 'daily' } });
          } else {

            // if(this.bas.envtest) console.log("BtPwPeriodGuestsComponent, timegridVisibleChange, !isAvailable, item: ", item);
          }
        }



        prev = item;
        // { start: '12:00', end: '13:59', recurring: { repeat: 'daily' } }// Trekke fra ett minutt på end
        // { start: '12:01', end: '14:00', recurring: { repeat: 'daily' } } // Legge til ett minutt på start
        /*
available: 1​
duration: 30​​​​​
durationAsString: "00:30"​​​​​
end: "22.05.24 10:30"​​​​​
endAsString: "2024-05-22 10:30"​​​​​
endTimeAsString: "10:30"​​​​​
hasChildAvailable: false​​​​​
id: 8000​​​​​
label: "10:00-10:30"​​​​​
productId: 3906​​​​​
start: "22.05.24 10:00"​​​​​
startAsString: "2024-05-22 10:00"​​​​​
startTimeAsString: "10:00"​​​​​
timePeriod: "10:00-10:30"​​​​​
timePeriodDisplay: "10:00-10:30"
        */
      }

      tg.validStart = validStart;
      tg.validEnd = validEnd;

      if(this.bas.envtest) console.log("BtPwPeriodGuestsComponent, timegridVisibleChange, tg: ", tg);
    } else {

    }


  }

  onTimegridChange(field:'start'|'end', event:any)  {
    if(this.bas.envtest) console.log("onTimegridChange, field: "+field+", event: " + event.valueText + ", tg: ", this.timegrid);

    let pw = this.pw;
    let ti = pw.timeItemsAvailable && pw.timeItemsAvailable[0];
    let tg = this.timegrid;

    let startTime = this.bas.ui.timeFormat(tg.start);
    let endTime = this.bas.ui.timeFormat(tg.end);

    if (startTime && endTime && endTime <= startTime) {
      tg.end = this.bas.ui.dateAdd(tg.start, { minutes: ti.duration || 30 })
      endTime = this.bas.ui.timeFormat(tg.end);
    }

    tg.label = startTime + " - " + endTime;


    this.timegridVisibleChange(true);

    pw.timePeriodAsString = tg.label.replace(" ", "");
    this.change.emit({ field: "timePeriodAsString", value:   pw.timePeriodAsString });
    

// ​event: 
// value: Date Wed May 22 2024 13:00:00 GMT+0200 (Central European Summer Time)
// valueText: "13:00"
  }


  dateOnPageLoading(event:any) {
    // if(this.bas.envtest) console.log("dateOnPageLoading, ", event, ", invalid: ", this.dateInvalid);
    this.dateUpdateInvalid(event);
  }

  dateOnChange(event:any) {
    if(this.bas.envtest) console.log("dateOnChange, startDateAsString: " + this.pw.startDateAsString
      + ", startDateAsJsDate: " + this.pw.startDateAsJsDate + ", event: ", event);
    this.pw.startDateAsString = this.bas.ui.dateToString(this.pw.startDateAsJsDate);
    // this.postChange();
    this.change.emit({ field: "startDateAsString", value:  this.pw.startDateAsString  });
  }

  dateRangeOnChange(event:any) {
    if(this.bas.envtest) console.log("dateOnChange, dateRangeOnChange: ", event)
    let dates:Date[] = event.value;
    if (dates === undefined || dates.length != 2 || dates[0] == null || dates[1] == null) return;
    this.pw.periodAsRange =  MiscUtil.datesToDateRange(event.value);
    this.change.emit({ field: "periodAsRange", value:   this.pw.periodAsRange });
  }

  onPeriodChange(event:any) {
    if(this.bas.envtest) console.log("onPeriodChange, event: ", event);
    this.change.emit({ field: "productPeriodAsString", value:   this.pw.productPeriodAsString });

  }

  onTimeChange(event:any) {
    if(this.bas.envtest) console.log("onTimeChange, event: ", event);
    this.change.emit({ field: "timePeriodAsString", value:   this.pw.timePeriodAsString });
  
  }

  

  onChange(event:any, field:string) {
    if(this.bas.envtest) console.log("onChange, event: ", event);


    this.change.emit({ field: field, value:   this.pw[field] });

  }

  dateUpdateInvalid(event:any) {
    let inst = event.inst;
    let fd = event.firstDay;
    let ld = event.lastDay;


    let invalid:any[] = [];
    
    let pw = this.pw;

    let dates:Date[] = this.bas.ui.getAllDatesBwtweenDates(fd, ld);

    // <c:if test="${wpd.company.username == 'skaperverkettonsberg'}"> <!-- TODO:hack -->
		// 	extraCalendarOptions = { daysOfWeekDisabled: [ 0,6 ]};
		// 	<c:if test="${fn:contains(p.name, 'Revetal')}">
		// 	  extraCalendarOptions = { daysOfWeekDisabled: [ 0 ]};
		// 	</c:if>
		// </c:if>


    let today = MiscUtil.getDateAsInt(new Date());

    
    let isSkaperverkettonsberg = this.bas.ds.findCid('pw-period-guests.dateUpdateInvalid') === "skaperverkettonsberg";

    for (let current of dates) {
      let dateInt = MiscUtil.getDateAsInt(current);
      if (dateInt < today) {
        invalid.push(current);
        continue;
      } 

      // if (isSkaperverkettonsberg) { // TODO: hack, dette burde gjøres annerledes
      //   let dow = current.getDay();
      //   if (dow === 0 || (dow === 6 && pw.productObj.mkName.indexOf("Revetal") < 0)) {
      //     invalid.push(current);
      //   }
      // }
   
      
      if (pw.selectableDatesNext && pw.selectableDatesNext.length) {
        if (MiscUtil.binarySearch(pw.selectableDatesNext, dateInt) < 0) {
          //console.log("current: " + current + " -> dateInt: " + dateInt + ", binarySearch: " + MiscUtil.binarySearch(pw.selectableDatesNext, dateInt) + ", pw: ", pw);

          invalid.push(current);
        }
      } else if (((pw.selectableDates && pw.selectableDates.length) || pw.productObj.timeProduct)) {
        if (MiscUtil.binarySearch(pw.selectableDates, dateInt) < 0)  invalid.push(current);
      } else if (pw.productObj.periodProduct && pw.occupiedDates) {
        if (MiscUtil.binarySearch(pw.occupiedDates, dateInt) >= 0)  invalid.push(current);
      }
    }


    if (isSkaperverkettonsberg) { // TODO: hack, dette burde gjøres annerledes
      invalid.push({
        recurring: {
          repeat: 'weekly',
          weekDays: (pw.productObj.mkName.indexOf("Revetal") < 0 ? 'SA,' : '' )+ 'SU'
        }
      })
    }

    //console.log("Setting dateInvalid: " + this.dateInvalid.length + " -> " + invalid.length);
    this.dateInvalid = invalid;



  }

 
  onGuestsUpdate(event:any) {
    if(this.bas.envtest) console.log("onGuestsUpdate, event: ", event);
    this.change.emit({ field: "guestCounts", value: event.value });
  }

}




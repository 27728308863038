

import { Component, Input, OnInit, Injector, EventEmitter, Output } from '@angular/core';

import { MiscUtil } from '../../../util/misc.util';
import { BtContent } from '../../../model/bt-content';

import { inject } from '@angular/core';

import { LibModulesWolcModule } from '../../../components/lib-modules-wolc.module';
import { BooktechAppService } from "../../../services/booktech-app.service";


@Component({
  selector: 'btlib-content-row',
  standalone: true,
  imports: [ LibModulesWolcModule ],
  templateUrl: './bt-content-row.component.html',
  styleUrl: './bt-content-row.component.scss'
})
export class BtContentRowComponent {
  bas = inject(BooktechAppService);

  @Input() cols: BtContent[] = [];

  @Input() grid: any = { };
  g = {
    xs: null,
    sm: null,
    md: null,
    lg: null,
    xl: null,
    xxl: null,
    gutter: 16
  };

  constructor() {
    
  }

  ngOnInit(): void {
    MiscUtil.extend(this.g, this.grid);

  }

}




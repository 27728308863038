import { Component, OnInit, Input } from '@angular/core';

import { DynformControl, DynformControlsMap } from '../../../model/dymform-control';
import { MiscUtil } from '../../../util/misc.util';

import { inject } from '@angular/core';

import { LibModulesWolcModule } from '../../../components/lib-modules-wolc.module';
import { BooktechAppService } from "../../../services/booktech-app.service";


@Component({
  selector: 'bt-table-data',
  standalone: true,
  imports: [ LibModulesWolcModule ],
  templateUrl: './bt-table-data.component.html',
  styleUrl: './bt-table-data.component.scss'
})
export class BtTableDataComponent {
  bas = inject(BooktechAppService);

  // opts:any = { }

  d:any = {
    current: []
  };


  @Input() 
  set data(data: any) {
    if (!data) return;

    let d = data;

    this.setData(data);
  }

  @Input() 
  opts:any = { }
  
  sortFns:any = { }

  msad:any = { };


  constructor() {
    if(this.bas.envtest) console.log("BtTableDataComponent, login: ", this.bas.ds.login);
      

  }

  ngOnInit(): void {
    
  }

  
  findAlign(align:string) {
    return align == "right" ? "right" : (align == "center" ? "center" : "left");
  }

  // openOrder(bid:string) {
  //   if(this.bas.envtest) console.log("openOrder, commands: ",  (this.bas.ui.getRouterPrefix() + '/order/' + bid).split("/"), ", bid: " + bid);

  //   // TODO :bruke Hook, slik at BergenSportsreiser og andre med spesielle bestilling-sider kan bruke denne også
  //   this.bas.ui.router.navigate(
  //     (this.bas.ui.getRouterPrefix() + '/order/' + bid).split("/")
  //   , { queryParams: {  }});

  // }


  setData(d:any) {

    let idx = 0;
    d.hasBid = false;
    for (let h of d.header ||  []) {
      if (h.customType) {
        d.hasBid = d.hasBid || h.customType == "bid";
      }
      idx++;
    }

  

    idx = 0;
    for (let h of d.header ||  []) {

      
      let hIdx = idx;
      this.sortFns[idx] = (a: any, b: any) => {

        let o1 = a.items[hIdx];
        let o2 = b.items[hIdx];

        let f1 = o1.value !== undefined ? o1.value : o1.text;
        let f2 = o2.value !== undefined ? o2.value : o2.text;
        
        if (typeof f1 === "number") {
          return f1 - f2;
        }
        if (typeof f1 !== "string" || !f1) f1 = "";
        if (typeof f2 !== "string" || !f2) f2 = "";
  
       
  
        return f1.localeCompare(f2);
  
      }

      idx++;
    }

    for (let row of d.current || []) {
      // order.jsonData = order.jsonData || { };
      // order.jsonData.metadata = order.jsonData.metadata || { };
     
    }

    this.d = d;
  }
  
  

  miscStatsAction(cell:any, header:any) {
    if (this.bas.envtest) console.log("miscStatsAction, cell: ", cell);

    let params:any = {
      aType: "appAdmin",
      action: "getMiscStatsFbo",
      // action: "miscStatsAction",
      // input: JSON.stringify(cell.buttonParams)
    };

    MiscUtil.extend(params, {
      input: JSON.stringify({
        statsType: cell.statsType,
        fieldValues: cell.fieldValues
      }
      )
    });

    this.bas.ws.json(params ).then((json:any) => {
      if (this.bas.envtest) console.log("miscStatsAction.then, json: ", json);

      // this.df.loading = false;

      if (json.success) {

        let fbo = json.fbo;

        fbo.visible = true;
        fbo.cell = cell;
        fbo.cellHeader = header;

       
        this.msad = fbo;

      } else {

      }
      

     });

  }


}



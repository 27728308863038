
import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { BtEvent } from '../../services/event.service';
import { HookType } from '../../services/hook.service';

import { MiscUtil } from '../../util/misc.util';

import { inject } from '@angular/core';

import { LibModulesModule } from '../../components/lib-modules.module';
import { BooktechAppService } from "../../services/booktech-app.service";

@Component({
  selector: 'btlib-page-api',
  standalone: true,
  imports: [ LibModulesModule ],
  templateUrl: './api.page.html',
  styleUrl: './api.page.scss'
})
export class ApiPage {

  bas = inject(BooktechAppService);

  route:ActivatedRoute = inject(ActivatedRoute);
  // qp:any = { }


  processing = false;


  constructor() {

    this.bas.us.checkActivatedRoute(this.route.snapshot);
    

    let qp =  this.route.snapshot.queryParams;


    if(this.bas.envtest) console.log("ApiPage, route.snapshot: ", this.route.snapshot);
    

    this.bas.hs.on(
      HookType.WebApiRequest, 
      (params) => this.processRequest(params)
    );

    this.bas.hs.trigger(HookType.WebApiRequest, {
      queryParams: qp,
  
    })

  }

  ngOnInit(): void {
    
  }

  processRequest(params:any) {

    this.bas.api.apiPageShowMessage(params.queryParams);

    return new Promise<boolean>((res, rej) => {

      let qp = params.queryParams;

      let page = decodeURIComponent( qp["cbapi-page"] );
      let action = qp["cbapi-action"];
      let toolsAction = qp["cbapi-tools-action"];


      let target = "/";
      let queryParams:any = { };

      for (let pn in qp) {
        if (!pn.startsWith("cbapi-option-")) continue;
        let name = pn.substring("cbapi-option-".length);
        queryParams[name] = qp[pn];
      }


      


      if (page) {

        target = "/" + page;

        
        if (page == "index") {
          target = "/";
        // } else if (page == "order/payment") {

        //   target = "/lib" + target; // /lib/order/payment/?id=dfdf
        } 
        else if (page == "confirmUser") {

          target =  "/lib/user/confirm";

          queryParams = {
            action: toolsAction
          }
        }


        
      }

      if (action) {
        if (action == "showNewPasswordDialog") {
          // TODO:TEST
          target = "/lib/user/password";

  
          // this.bas.ws.login({
          //   checkLogin: true,
          //   uid: qp["cbapi-uid"] || "",
          //   accessKey: qp["cbapi-ak"] || "",
          //   callback: (al:any) => {
          //     console.log("al: ", al);
          //     if (!al.success) {
          //       target = "/";
          //     }
          //     this.bas.ui.router.navigate( [ target ] );

          //   }
          // }).toPromise().then(() => { });

          return;
        }

      }



      // if(this.bas.envtest) 
        console.log("target: " + target + ", queryParams: ", queryParams);
      

      // Denne klasse viderersener ikke?
      // this.bas.ui.router.navigate(
      //   [ target ], 
      //   { queryParams: queryParams }
      // );

    });
  }
}





<div *ngIf="bas.ds.login.success"  >
  
  <div class="m-d">

    <button nz-button nzType="default" nz-dropdown  nzTrigger="click" [nzDropdownMenu]="menuAdmin" id="btn-user-menu" >
      <i nz-icon [nzType]="'menu'" ></i> <span>{{ 'app.lib.common.menu' | actrans  }}</span>
    </button>

  </div>


  <nz-dropdown-menu #menuAdmin="nzDropdownMenu">
    <!-- <ul nz-menu>
      <li nz-menu-item [routerLink]="bas.ui.getRouterPrefix() + '/admin/'" >{{ 'common.admin' | actrans  }}</li>
      <li nz-menu-item [routerLink]="bas.ui.getRouterPrefix() + '/admin/products'">{{ 'common.products' | actrans  }}</li>
      <li nz-menu-item [routerLink]="bas.ui.getRouterPrefix() + '/admin/orders'">{{ 'web.common.bookings' | actrans  }}</li>
      <li nz-menu-item [routerLink]="bas.ui.getRouterPrefix() + '/admin/order-overview'">{{ 'web.common.overview' | actrans  }}</li>
      <li nz-menu-item [routerLink]="bas.ui.getRouterPrefix() + '/common/user/user'">{{ 'web.common.user' | actrans  }}</li>
      <li nz-menu-item [routerLink]="bas.ui.getRouterPrefix() + '/admin/reports'">{{ 'web.common.reports' | actrans  }}</li>
      <li nz-menu-item [routerLink]="bas.ui.getRouterPrefix() + '/admin/stats'">{{ 'web.common.stats' | actrans  }}</li>
     
    </ul> -->


  <div *ngIf="bas.aas.isSubuser()" >
      <ul nz-menu *ngIf="bas.ds.login.user.parentUserRoleName == 'Provider'">

        <li nz-menu-item [routerLink]="bas.ui.getRouterPrefix() + '/subuser/provider/overview'">{{ 'web.common.overview' | actrans  }}</li>
        
      </ul>
     
  </div>
  <ul *ngIf="bas.ds.login.success" nz-menu>
    <!-- <li nz-menu-divider></li> -->
    
    <li nz-menu-item (click)="bas.api.logout()">{{ 'app.lib.common.logout' | actrans  }} </li>
    
  </ul>

  </nz-dropdown-menu>


</div>


<btlib-login-check  ></btlib-login-check>


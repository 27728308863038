<div *ngIf="control">
  <strong>{{ control.mk ? (control.mk | actrans) : control.label }}</strong>
  <nz-tabset nzSize="small">
    <nz-tab *ngFor="let child of control.children" [nzTitle]="((child.data.langName | actrans | str))"> 
      <bt-dynamic-form-control [control]="child" [form]="fg()" (change)="onChange($event)" ></bt-dynamic-form-control>
    </nz-tab>

  </nz-tabset>
  
</div>


import { inject } from '@angular/core';
import { MiscUtil } from '../util/misc.util';
import { BooktechAppService } from './booktech-app.service';

import { HttpClient, HttpParams, HttpUrlEncodingCodec } from '@angular/common/http';

import { Observable, EMPTY } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DATAID } from './data.service';
import { BtEvent } from './event.service';


const CB_URL_TEST = "https://test.cloud-booking.net";
const CB_URL_PROD = "https://www.cloud-booking.net";


export class CustomQueryEncoderHelper extends HttpUrlEncodingCodec {
  override encodeKey(k: string): string {
      k = super.encodeKey(k);
      return k.replace(/\+/gi, '%2B');
  }
  override encodeValue(v: string): string {
      v = super.encodeValue(v);
      return v.replace(/\+/gi, '%2B');
  }
}

export class WebService {

  private http: HttpClient = inject(HttpClient);
  serverConnected = false;

  constructor(public bas: BooktechAppService ) {
    if (this.bas.envtest) console.log("WebService.constructor");

  
    
    // this.apps.es.ready().then(() => {
    //   if (this.bas.envtest) console.log(MiscUtil.getLogText("WebService.ready1", true));
    // })

    // setTimeout(() => {
    //   this.apps.es.ready().then(() => {
    //     if (this.bas.envtest) console.log(MiscUtil.getLogText("WebService.ready2", true));
    //   })

    // }, 2000);
   
  }





  getCommonHeaders(options:any = { }):any {
    var lang = this.bas.ds.findLang(); 
    var device = this.bas.ds.device;
    var deviceUuid = device?.uuid;

    let headers:any = {
      "cb-app-type": this.bas.settings.appType,
      "cb-app-id": this.bas.settings.appId,
      "cb-app-platform": this.getPlatformString(),
      "cb-lang": lang || "no",
      "cb-uuid": deviceUuid || "",
      "cb-cid": options?.cid || this.bas.ds.findCid('ws.getCommonHeaders')
    }

    return headers;
  }



  getLoginHeaders():any {
    let al = this.bas.ds.login;
    // console.log("getLoginHeaders: " + al.accessToken);
    var headers = al.success && al.accessToken ? {
      "Authorization": "Bearer " + al.accessToken,
    } : { };
    return headers;

    // cb-auth-token
  }
  

  appendBttoken(url:string):string {
    let al = this.bas.ds.login;
    if (!url || !al.success || !al.accessToken) return url;

    return this.appendParameter(url, "bttoken", al.accessToken);   
  }

  appendParameter(url:string, name:string, value:any) {
    if (!url || !name) return url;
    return url 
      + ( url.indexOf("?") ? "&" : "?")
      + name + "=" +  encodeURIComponent(
        value !== undefined ? value : ""
      );
  }


  getJsonUrl(params:any, cid?:string):string {
    return this.getBaseUrlJson(cid) + "" + this.getParamsAsString(params);
  }

  getParamsAsString(params:any):string {
    var str = "";

    var i = 0;
    for (let name in params) {
      if (i != 0) str += "&";
      str += name + "=" + encodeURIComponent(params[name]);
      i++;
    };
    return str;
  }

  includeSource(params:any, qps?:any, toString:boolean = false):any {
    if (params == undefined) params = { };
    if (!qps) qps = this.bas.ds.getValue(DATAID.APP_URL_QUERY_PARAMS);
    //if (this.bas.envtest) console.log("WebService.includeSource. qps: ", qps);
        
    if (!qps) return params;

    if (qps.source) params.source = qps.source;

    if (toString) return this.getParamsAsString(params);
    
    return params;
  }

  async updateAccessToken() {

    let al = this.bas.ds.login;
    if (al.success) { 
      let json = await this.json({ 
        aType: "auth", 
        action: "getAccessToken" 
      }, { 
        headers: { "cb-auth-token": al.refreshToken },
        excludeLoginHeaders: true,
        checkAuthStatus: false
      });
      return json;
    }

    return { success: false };

  }

  

  async json(inputParams:any = { }, options:any = { }):Promise<any> {
    // console.log("json, inputParams: ", inputParams, ", options: ", options);

    const inputParamsConst = MiscUtil.extend({ }, inputParams);
    const optionsConst = MiscUtil.extend({ }, options);

    let checkSuccess = options.checkSuccess !== false;

    let json = await this.jsonRequest(inputParams, options).toPromise();
    //console.log("json.response: ", json);

    if (json && json.success ) {

      if (json.operatingMessages?.show) {
        let oms = json.operatingMessages;
        // let message =  this.bas.ui.trustUrl((oms.updateTime + oms.message).trim());
        let message =  (oms.updateTime + oms.message).trim();

        // console.log("saving APP_WEB_OPERATING_MESSAGES: ", message);
        this.bas.ds.set(DATAID.APP_WEB_OPERATING_MESSAGES, { message });
      }

      return json;
    }

    if (json == null) json = { success: false, statusDesc: this.bas.ui.actrans("app.lib.error.requestFailed", [], false, "Tilkobling til serveren feilet. Vennligst prøv igjen eller kontakt kundeservice om problemet vedvarer.") }; 

    if (Array.isArray(json)) checkSuccess = false;

    // console.log("json.success == false, json: ", json);

    if (options.authRetry) return json;

    let al = this.bas.ds.login;
    if (al.success) { 
      if (json.statusCode == "web.servlet.json.auth.invalidAccessToken" && al.refreshToken) {
        // Hente ny token og prøve på nytt
  
        let atJson = await this.json({ 
          aType: "auth", 
          action: "getAccessToken" 
        }, { 
          headers: { "cb-auth-token": al.refreshToken },
          excludeLoginHeaders: true,
          checkAuthStatus: false
        });
        if (atJson.success) {
          al = this.bas.ds.login;
          if (this.bas.envtest) console.log("invalidAccessToken, at: " + al.accessToken + " -> " + atJson.accessToken); 
          al.accessToken = atJson.accessToken;
          await this.bas.ds.save(DATAID.APP_LOGIN, al);
  
          options.authRetry = true;
          // console.log("token updated, retrying request, inputParams: ", inputParams, ", options: ", options);
          let retryResponse = await this.jsonRequest(inputParamsConst, optionsConst).toPromise();
          // console.log("retryResponse: ", retryResponse);
          return retryResponse;
        }
  
  
  
      } else if (json.statusCode == "web.servlet.json.auth.invalidRefreshToken") {
        // DONE: vise feilmelding og spørre om brukeren vil logge inn på nytt. Skjer ikke dette automatisk ?
  
        await this.bas.ds.save(DATAID.APP_LOGIN, { success: false });
  
  
  
      } 
      // else if (json.statusCode == "web.common.error.accessDenied") { // Denne koden brukes på andre ting også, så trenger ikke logge ut bruekren. 
      //   // TODO: vise feilmelding og spørre om brukeren vil logge inn på nytt. 
  
      //   await this.bas.ds.save(DATAID.APP_LOGIN, { success: false });

      // }
    }

    if (checkSuccess) {

      this.bas.ui.notification("error", this.bas.ui.actrans("app.lib.common.error"), "<p>"+json.statusDesc+"</p>", 10); // <p>En feil har oppstått</p>

    }
    
    return json;


  }

  private jsonRequest(inputParams:any = { }, options:any = { }):Observable<any> {


    if (options.cid === undefined) {
      options.cid = this.bas.ds.findCid('ws.jsonRequest');
      // if (this.bas.ds.config?.cid) options.cid = this.bas.ds.config?.cid;
      // else if (this.bas.ds.getValue(DATAID.APP_URL_PARAMS).cid) options.cid = this.bas.ds.getValue(DATAID.APP_URL_PARAMS).cid;
    }

    // if (inputParams.aType == "customAction" && this.bas.settings.appId && inputParams.appId == undefined) {
    //   inputParams.appId = this.bas.settings.appId;
    // }
    
    let excludeLoginHeaders = options.excludeLoginHeaders;
    var params = inputParams; // this.getJsonParams(inputParams);

    var headers:any = this.getCommonHeaders(options);
  
    if (!excludeLoginHeaders)  MiscUtil.extend(headers, this.getLoginHeaders());
    if (options.headers) {
      MiscUtil.extend(headers, options.headers);
    }

    options.headers = headers;

    let showLoading = options.showLoading; //TODO
    let checkAuthStatus = inputParams.checkAuthStatus !== false; // ? options.checkAuthStatus : true;

    if (checkAuthStatus && this.bas.ds.login.success) params.checkAuthStatus = checkAuthStatus;

    
    

    // console.log("WebService.json: options: ", options, ", params: ", params, ", headers: ", headers, ", cid: " + options.cid, "; config: ", this.bas.ds.config);

    // if (showLoading) this.bas.us.showLoading();

    options.contentType = options.contentType || 'application/x-www-form-urlencoded';

    return this.httpRequest(this.getBaseUrlJson(options.cid), params, options).pipe(tap({
      next: res => {
        //Log.log("WebService, sucess, typeof res: " + (typeof res));
        if (!this.serverConnected && this.bas.envtest) console.log("WebService, serverConnected changed from false to TRUE");
        this.serverConnected = true;
      },
      error: error => {
        if (this.bas.envtest) console.log("WebService, error: " + MiscUtil.stringify(error));
        if (this.serverConnected) console.log("WebService, serverConnected changed from true to FALSE");
        this.serverConnected = false;
        this.handleError(error);
      },
      complete: () => {
        //Log.log('on complete')
        // if (showLoading) this.apps.us.hideLoading();
      }
    }));
  }

 
  private handleError(error: any): Promise<any> {
    if (this.bas.envtest) console.log('WebService, An error occurred: ' + MiscUtil.stringify(error));
    return Promise.reject(error.message || error);
  }
  

  httpRequest(url:string, params?:any, options?:any):Observable<any> {
    options = options || { }; 
    let method = options.method || "post";
    // let contentType = options.contentType;
    var postBody = options.postBody;

    
    let hp = new HttpParams({ encoder: new CustomQueryEncoderHelper(), fromObject: params });
    //console.log("httpRequest, method: "+method+", postBody: " +postBody+", params: ", params);

    var httpOptions:any = {
      params: hp
    };

    if (method == "post" && !postBody) {

      httpOptions.params = new HttpParams();
      postBody = hp.toString();
      // Log.l("httpRequest: postBody: ", postBody);

    }


    httpOptions.headers = options.headers || { };
    if (options.contentType) {
      
      httpOptions.headers["Content-Type"] = options.contentType;
    }


    if (url.startsWith(this.getBaseUrl())) {
      httpOptions.withCredentials = true;
    }

    // console.log("httpRequest.headers: ", httpOptions.headers);

    if (method == "get") {
      if (this.bas.envtest) console.log("httpRequest.get: calling http.get");
      return this.http.get(url, httpOptions);
    }
    else if (method == "post") {
      // Log.l("httpRequest.post: calling http.post, url: " + url + "; postBody: " + postBody + ", httpOptions: ", httpOptions);
      let postRes = this.http.post(url, postBody, httpOptions);
      // postRes.subscribe((obj) => {
      //   Log.l("httpRequest.postRes: ", obj); 
      // });
      return postRes;
    }

    return EMPTY;
  }

 

  getPlatformString() {
    var platformString = "browser";

    // if (this.platform.is("cordova") || this.platform.is("capacitor")) {
    //   if (this.platform.is("android")) platformString = "android";
    //   else if (this.platform.is("ios")) platformString = "ios";
    // }
    return platformString;
  }


  
  getBaseUrl(cid: string = "") {
    cid = cid || this.bas.ds.findCid('ws.getBaseUrl');
    return this.getBaseUrlCb() + "/" + cid;
  }

  getBaseUrlJson(cid: string = "") {

    cid = cid || this.bas.ds.findCid('ws.getBaseUrlJson');
    return this.getBaseUrlCb() + "/servlet/json/" + cid + "/_";
  }
  getBaseUrlTools(cid: string = "") {

    cid = cid || this.bas.ds.findCid('ws.getBaseUrlTools');
    return this.getBaseUrlCb() + "/servlet/tools/" + cid + "/_";
  }

  getBaseUrlCb() {
    return this.bas.envprod ? CB_URL_PROD : CB_URL_TEST;
  }

  getBaseUrlStatic() {
    return this.getBaseUrlCb() + "/static";
  }
}

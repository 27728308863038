/* lib-modules-common.module.ts */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuillModule, QuillEditorComponent } from 'ngx-quill'
import { MbscModule } from '@mobiscroll/angular-ivy';



@NgModule({
  declarations: [

  ],
  imports: [
    MbscModule,
    CommonModule,
    QuillModule.forRoot(),
    QuillEditorComponent,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,

  ],
  exports: [
    MbscModule,
    CommonModule,
    QuillEditorComponent,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,


  ]
})
export class LibModulesCommonModule {}


<nz-modal 
  [nzVisible]="!!edit.obj" 
  [nzTitle]="'web.common.user.deleteUser' | actrans | str" 

  (nzOnCancel)="onCancel()" 
  (nzOnOk)="onOk()"
  >
  <ng-container *nzModalContent>
    
    <div *ngIf="edit.obj">

      <form nz-form nzLayout="vertical" [nzAutoTips]="bas.fs.autoTips">
        
        <btlib-form-row  [controls]="[ edit.controls.confirmDelete ]" [form]="edit.form"  [grid]="bas.ui.gridFull" (change)="onChange($event)" ></btlib-form-row> 
        <div *ngIf="bas.aas.isAdmin()">
          <btlib-form-row  [controls]="[ edit.controls.force ]" [form]="edit.form"  [grid]="bas.ui.gridFull" (change)="onChange($event)" ></btlib-form-row> 
    
        </div>
        
      </form>
    </div>

  </ng-container>

</nz-modal>


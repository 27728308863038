<div nz-row [nzGutter]="grid.gutter"  nzAlign="middle">
  <div 
    *ngFor="let control of allControls" 
    [class.bt-row-hide]="control && !control.show(control)" 
    nz-col 
    [nzXs]="control?.grid?.xs || grid.xs" 
    [nzSm]="control?.grid?.sm || grid.sm" 
    [nzMd]="control?.grid?.md || grid.md" 
    [nzLg]="control?.grid?.lg || grid.lg" 
    [nzXl]="control?.grid?.xl || grid.xl" 
    [nzXXl]="control?.grid?.xxl || grid.xxl">
    
    <nz-alert 
      nzType="error" 
      *ngIf="!control" 
      nzMessage="CONTROL is undefined">
    </nz-alert>
    
    <bt-message-field 
      *ngIf="control && control.controlType == 'message' && control.show(control)" 
      [control]="control" 
      [form]="control.fg || form" 
      (change)="onChange($event)">
    </bt-message-field>

    <bt-dynamic-form-control 
      *ngIf="control && control.controlType != 'message'" 
      [control]="control" 
      [form]="control.fg || form" 
      [options]="options" 
      (change)="onChange($event)" >
    </bt-dynamic-form-control>
  </div>


</div>

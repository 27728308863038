import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { inject } from '@angular/core';

import { LibModulesWolcModule } from '../../../components/lib-modules-wolc.module';
import { BooktechAppService } from "../../../services/booktech-app.service";


@Component({
  selector: 'bt-style-popover',
  standalone: true,
  imports: [ LibModulesWolcModule ],
  templateUrl: './bt-style-popover.component.html',
  styleUrl: './bt-style-popover.component.scss'
})
export class BtStylePopoverComponent {
  bas = inject(BooktechAppService);

  defaultStyles = [
    // "bts-none", 
    "bts-green",
    "bts-yellow",
    "bts-orange",
    "bts-red",

  ];


  @Input('presets') presets: string[] = this.defaultStyles;

  @Input('size') size: string = "default";
  @Input('style') style: string = "";
  @Output() change = new EventEmitter<any>();
  
  constructor() {

  }

  ngOnInit(): void {
    
  }

  onClick(style:string) {
    this.style = style;
    this.change.next(style);
  }

}



import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HookType } from '../../services/hook.service';


import { inject } from '@angular/core';

import { LibModulesModule } from '../../components/lib-modules.module';
import { BooktechAppService } from "../../services/booktech-app.service";

@Component({
  selector: 'btlib-page-goto',
  standalone: true,
  imports: [ LibModulesModule ],
  templateUrl: './goto.page.html',
  styleUrl: './goto.page.scss'
})
export class GotoPage {

  bas = inject(BooktechAppService);
  route:ActivatedRoute = inject(ActivatedRoute);

  processing = false;


  constructor() {

    this.bas.us.checkActivatedRoute(this.route.snapshot);
    

    let qp =  this.route.snapshot.queryParams;


    if(this.bas.envtest) console.log("GotoPage, route.snapshot: ", this.route.snapshot);
    


    this.bas.hs.on(
      HookType.WebGotoRequest, 
      (params) => this.processGotoRequest(params)
    );

    this.bas.hs.trigger(HookType.WebGotoRequest, {
      queryParams: qp,
  
    })

    

  }

  ngOnInit(): void {
    
  }

  // https://192.168.1.81:8202/api/opplevkragero/lib/goto?goto-action=order&goto-id=AFQ002
  // https://192.168.1.81:8202/api/bergensportsreiser/lib/goto?goto-action=order&goto-id=AFN094
  processGotoRequest(params:any) {
    if(this.bas.envtest) console.log("GotoPage, processRequest: ", params);
    

    return new Promise<boolean>((res, rej) => {

      let qps = params.queryParams;

      let action = qps["goto-action"];
      let id = qps["goto-id"];
      
      let target = "";
      let queryParams:any = { };

      for (let pn in qps) {
        if (pn.startsWith("goto-")) continue;

        queryParams[pn] = qps[pn];
      }

      let appid = this.bas.settings.appId;

      if (action) {
        if (action == "order") {

          if (appid == "cbadmin") {
            target = this.bas.ui.getRouterPrefix() + "/order/order/" + id;
            // delete queryParams.id;
          }

        }

      }



      if(this.bas.envtest) console.log("target: " + target + ", queryParams: ", queryParams);
      

      if (target) {
        this.bas.ui.router.navigate(
          [ target ], 
          { queryParams: queryParams, replaceUrl: true }
        );
  
        res(false);
      }


      res(true); // går til neste
    });
  }
}




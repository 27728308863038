import { Pipe, PipeTransform, inject } from '@angular/core';
import { BooktechAppService } from "../services/booktech-app.service";
import { SafeHtml } from '@angular/platform-browser';



/*
*/
@Pipe({name: 'messagetrans', standalone: true})
export class MessageTransPipe implements PipeTransform {

  bas = inject(BooktechAppService);
  


  transform(key: any, params:any[] = [ ], safe: boolean = false, fallback?:string):  (string | SafeHtml) {
    //Log.l("value.type: " + typeof value + ", value: ", value);
    
    return this.bas.ui.messagetrans(key, params, safe, fallback);
  }
}

import { Pipe, PipeTransform, inject } from '@angular/core';
import { BooktechAppService } from "../services/booktech-app.service";



@Pipe({ name: "nf", standalone: true })
export class NfPipe implements PipeTransform {

  bas = inject(BooktechAppService);
  


  transform(value: number | string, fractionSize: number = 2): string {
    return this.bas.ui.nf(value, fractionSize);

  }

  parse(value: string, fractionSize: number = 2): number {
    return this.bas.ui.nfparse(value, fractionSize);

  }

}


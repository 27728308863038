

<nz-layout>

  <nz-content>
    <div class="box-wrapper">
      <div class="box-div">

        <div class="px-4">
          <img 
          [src]="bas.ws.getBaseUrlCb() + '/pf/img/company/'+bas.ds.findCid('lib.user.confirm')+'/logo_1000x200.png'" 
          style="" 
          class="w-100 mb-d"
        />
        </div>
  
        <div></div>

        <div *ngIf="data.error">
          {{ data.error | actrans }}
        </div>

        <div *ngIf="!data.error">

        
          <!-- <p>{{ ''  | actrans }}</p> -->

          <div class="text-center" *ngIf="step == 0">
            <p class="text-center  mt-8">{{ 'app.lib.pages.user.confirm.desc'  | actrans }}</p>

            <p class="">
              <button nz-button nzType="primary" [nzLoading]="data.loading" (click)="confirm()">{{ 'app.lib.pages.user.confirm' | actrans }}</button>
            </p>
          </div>
          
          <div *ngIf="step == 1">
            <p class="text-center">{{ 'app.lib.pages.user.confirm.enterNewPassword' | actrans }}</p>

            <form nz-form [formGroup]="edit.form" (ngSubmit)="savePassword()"  class="item-form " nzLayout="vertical" [nzAutoTips]="bas.fs.autoTips" >
              <btlib-form-row [controls]="[ 
                edit.controls.passwordNew,
                edit.controls.passwordConfirm,

              ]" [form]="edit.form"  [grid]="bas.ui.gridFull"  [options]="edit.controlOptions || { nomargin: true }" ></btlib-form-row> 

              
              <nz-alert 
                *ngIf="edit.error"
                class="my-8" 
                nzType="error" 
                [nzMessage]="edit.error | actrans | str" 
                nzShowIcon>
              </nz-alert>

              <button  class="mt-d"  nz-button nzBlock nzType="primary" [nzLoading]="data.loading">{{ 'app.lib.common.save' | actrans }}</button>

            </form>
          </div>
          
          <div *ngIf="step == 2">
            <p class="text-center">{{ 'app.lib.pages.user.confirm.confirmed' | actrans }}</p>

            <button nz-button nzBlock nzType="primary" (click)="gotoHome()">{{ 'app.lib.common.ok' | actrans }}</button>

	          <!-- <a href="" class="btn btn-lg btn-success btn-block btn-exit"><nit:message key="common.ok" ct="false" /></a> -->
	      
          </div>
  

	      
          <!--
           <div id="fcPassword" class="hide">
	          <label for="pPassword" class="sr-only"><nit:message key="web.common.user.passwordNew"/></label> 
	          <input type="password" id="pPassword" name="passwordNew"  class="form-control fc-first jpField jpRequire" data-jp-min-length="6"  placeholder="<nit:message key="web.common.user.passwordNew" ct="false"/>" required autofocus> 
	          <label for="pPasswordConfirm" class="sr-only"><nit:message key="web.common.user.passwordConfirm"/></label> 
	          <input type="password" id="pPasswordConfirm" name="passwordConfirm" class="form-control fc-last jpField jpRequire" data-jp-min-length="6"  placeholder="<nit:message key="web.common.user.passwordConfirm" ct="false"/>" required>
	          <a href="#" class="btn btn-lg-off btn-primary btn-block jpSubmit"><nit:message key="common.save" ct="false" /></a>
	        </div>
	        
	        <div id="pwChanged" class="bs-callout bs-callout-success hide">
	          <nit:message key="web.common.info.passwordIsChanged"></nit:message>
	        </div>
	        
	        
	        <div id="confirmedWrapper" class="hide">
	          <p><nit:message key="web.api.confirmUser.confirmed"/></p>
	          <a href="<nit:url value="/tools.htm" pn1="action" pv1="goto" pn2="where" pv2="home" />" class="btn btn-lg btn-success btn-block btn-exit"><nit:message key="common.ok" ct="false" /></a>
	      
	        </div>
		        
          -->

        </div>
        
        
      </div>
    </div>


  </nz-content>

</nz-layout>

/// <reference types="@types/google.maps" />
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Injectable, Inject, Injector } from '@angular/core';

import { Input, Renderer2, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';

const GOOGLE_API_KEY = "AIzaSyDZStYkYrI_h8dQORO39AAAeZ_CwvdIOHU"; // TODO

import { inject } from '@angular/core';

import { LibModulesWolcModule } from '../../../components/lib-modules-wolc.module';
import { BooktechAppService } from "../../../services/booktech-app.service";


@Component({
  selector: 'bt-google-maps',
  standalone: true,
  imports: [ LibModulesWolcModule ],
  templateUrl: './bt-google-maps.component.html',
  styleUrl: './bt-google-maps.component.scss'
})
export class BtGoogleMapsComponent {
  bas = inject(BooktechAppService);

  @Input('params') params: any = { };
  @Output() mapClick = new EventEmitter<any>();
  @Output() mapSelectPosition = new EventEmitter<google.maps.LatLng>();

  public map: google.maps.Map | undefined = undefined;
  public markers: google.maps.Marker[] = [];
  private openInfoWindow:google.maps.InfoWindow | undefined = undefined;
  private mapsLoaded: boolean = false;
  private isReady: boolean = false;
  private readyPromise: Promise<any> | undefined = undefined;
  private networkHandler = null;
  private selectPositionMarker :google.maps.Marker|undefined = undefined;

  private renderer: Renderer2 = inject(Renderer2);
  private element: ElementRef = inject(ElementRef);
  private _document: Document = inject(DOCUMENT);

  constructor( ) {

      

   }

  ngOnInit() {

    this.readyPromise = this.init();

    // TODO: You have included the Google Maps JavaScript API multiple times on this page. This may cause unexpected errors.
    // Må lagre i DataService om det er lastet inn eller ikke

    this.readyPromise.then((res) => {
      this.isReady = true;
      if(this.bas.envtest) console.log("Google Maps ready.");

      let center = this.map!.getCenter();
      //this.addMarker(center.lat(), center.lng());

    }, (err) => {    
      if(this.bas.envtest) console.log(err);
    });

  }


  public ready(): Promise<any> {
    return new Promise((resolve, reject) => {
        if (this.isReady) {
            resolve(true);
        } else {
            this.readyPromise!.then(() =>  {
                resolve(true);
            });
        }
    });

  }

  private init(): Promise<any> {
    //console.log("init");
    return new Promise((resolve, reject) => {

        this.loadSDK().then((res) => {

            this.initMap().then((res) => {
                resolve(true);
            }, (err) => {
                reject(err);
            });

        }, (err) => {

            reject(err);

        });

    });

}

private loadSDK(): Promise<any> {

    if(this.bas.envtest) console.log("Loading Google Maps SDK");

    return new Promise((resolve, reject) => {

        if(!this.mapsLoaded){

            // Network.getStatus().then((status) => {

            //     if(status.connected){

            //         this.injectSDK().then((res) => {
            //             resolve(true);
            //         }, (err) => {
            //             reject(err);
            //         });

            //     } else {

            //         if(this.networkHandler == null){

            //             this.networkHandler = Network.addListener('networkStatusChange', (status) => {

            //                 if(status.connected){

            //                     this.networkHandler.remove();

            //                     this.init().then((res) => {
            //                         if(this.bas.envtest) console.log("Google Maps ready.")
            //                     }, (err) => {    
            //                         if(this.bas.envtest) console.log(err);
            //                     });

            //                 }

            //             });

            //         }

            //         reject('Not online');
            //     }

            // }, (err) => {

                // NOTE: navigator.onLine temporarily required until Network plugin has web implementation
                if(this.bas.ssr || navigator.onLine){

                    this.injectSDK().then((res) => {
                        resolve(true);
                    }, (err) => {
                        reject(err);
                    });

                } else {
                    reject('Not online');
                }

            // });

        } else {
            reject('SDK already loaded');
        }

    });


}

private injectSDK(): Promise<any> {

    return new Promise((resolve, reject) => {

      let script = this._document.getElementById("googleMaps") as HTMLScriptElement;
      if (script) {
        if(this.bas.envtest) console.log("already loaded: ", script);
        resolve(true);
        return;
      }

      if(this.bas.envtest) console.log("script == false: ", script);
      
      if (this.bas.nossr) {
        let win:any = this.bas.nossr ? window : undefined;
        if(win) win['mapInit'] = () => {
            this.mapsLoaded = true;
            resolve(true);
        }
  
        script = this.renderer.createElement('script') as HTMLScriptElement;
        script.id = 'googleMaps';
  
        script.src = 'https://maps.googleapis.com/maps/api/js?key=' + GOOGLE_API_KEY + '&callback=mapInit';
        
  
        this.renderer.appendChild(this._document.body, script);
      }


    });

}

private initMap(): Promise<any> {


    return new Promise((resolve, reject) => {
      // console.log("params: ", this.params);
      let lat = this.params.coords?.latitude || 59.913490;  // Default:
      let lng = this.params.coords?.longitude || 10.752089  // Oslo sentrum

      let latLng = new google.maps.LatLng(lat, lng);

      let mapOptions = {
          center: latLng,
          zoom: this.params.zoom || 15    //
      };

      this.map = new google.maps.Map(this.element.nativeElement, mapOptions);
      this.addEventsListeners();
      resolve(true);


        /*
        Geolocation.getCurrentPosition().then((position) => {

            if(this.bas.envtest) console.log(position);

            let latLng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);

            let mapOptions = {
                center: latLng,
                zoom: 15
            };

            this.map = new google.maps.Map(this.element.nativeElement, mapOptions);
            resolve(true);

        }, (err) => {

            reject('Could not initialise map');

        });
      */

    });

  }

  private addEventsListeners() {
    let selectPosition = !!this.params.selectPosition;

    if (selectPosition && this.params.coords) {
      let lat = this.params.coords?.latitude;
      let lng = this.params.coords?.longitude;

      let latLng = new google.maps.LatLng(lat, lng);

      this.selectPositionMarker = new google.maps.Marker({
        map: this.map,
        animation: google.maps.Animation.DROP,
        position: latLng
      });
      this.markers.push(this.selectPositionMarker);

    }

    this.map?.addListener("click", (event:any) => {
      this.mapClick.next(event);

      if (selectPosition) {
        let latLng = event.latLng;
        if (this.selectPositionMarker) {

          this.selectPositionMarker.setPosition(latLng);
        } else {
          this.selectPositionMarker = new google.maps.Marker({
            map: this.map,
            animation: google.maps.Animation.DROP,
            position: latLng
          });
          this.markers.push(this.selectPositionMarker);
        }

        this.mapSelectPosition.next(latLng);
      }
    });
  }

  public addMarker(lat: number, lng: number, infoWindowElement:HTMLElement | null): google.maps.Marker {  // |String

    let latLng = new google.maps.LatLng(lat, lng);

    let marker = new google.maps.Marker({
        map: this.map,
        animation: google.maps.Animation.DROP,
        position: latLng
    });

    if (infoWindowElement) {
      //let gmc = this;
      google.maps.event.addListener(marker, 'click', (event:any) => {
        if (this.openInfoWindow) {
          //console.log("Closing existing");
          this.openInfoWindow.close();
          this.openInfoWindow = undefined;
        }
        this.openInfoWindow = new google.maps.InfoWindow({ content: infoWindowElement });
        
        this.openInfoWindow.open(this.map!, marker);

      });

    }


    this.markers.push(marker);

    return marker;
  }

  public removeMarker(marker:google.maps.Marker) {
    marker.setMap(null);
    this.markers.splice(this.markers.indexOf(marker), 1);
    
  }

  public clearMarkers() {
    for (let m of this.markers) {
      m.setMap(null);
    }
  
    this.markers.splice(0, this.markers.length);
    
  }


  public centerMap() {
    if (this.markers.length == 0) return;

    let setCenter = true;
    let llb = new google.maps.LatLngBounds();
    let allEqual = true;
    let prev:google.maps.LatLng = this.markers[0].getPosition() as google.maps.LatLng;
    for (let mm of this.markers) {
      let pos = mm.getPosition() as google.maps.LatLng;
      // if(this.bas.envtest) console.log("pos: " + pos + ", llb: " + llb);

      llb.extend(pos);

      if (!prev.equals(pos)) {
        allEqual = false;
      }
    }

    if (!allEqual) {
      // if(this.bas.envtest) console.log("fitBounds, llb: " + llb + ", curr " + this.map?.getBounds());

      this.map!.fitBounds(llb); 
      setCenter = false;
    }

    if(this.bas.envtest) console.log("centerMap, size: " + this.markers.length + ", eq: " + allEqual + "; sc: " + setCenter + ", map.bounds: " + this.map?.getBounds());

    if (setCenter) this.map!.setCenter(llb.getCenter());

  }

}



import { Component, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DynformControl } from '../../../model/dymform-control';
import { MiscUtil } from '../../../util/misc.util';

import { inject } from '@angular/core';

import { LibModulesModule } from '../../../components/lib-modules.module';
import { BooktechAppService } from "../../../services/booktech-app.service";

@Component({
  selector: 'btlib-page-confirm',
  standalone: true,
  imports: [ LibModulesModule ],
  templateUrl: './confirm.page.html',
  styleUrl: './confirm.page.scss'
})
export class ConfirmPage {

  bas = inject(BooktechAppService);
  route = inject(ActivatedRoute);

  step = 0;

  edit:any = { };

  data:any = {
    // a: "",
    // uid: "",
    // ck: "",
    error: ""
  }


  // https://192.168.1.81:8204/booking/nesbyen/no/lib/user/confirm?
  // ?action=confirmUserLp_2121_0Eh3W7io4xDmRyDscnLPh5fEXzBGOoYq
  constructor() {

    this.bas.us.checkActivatedRoute(this.route.snapshot);
    if(this.bas.envtest) console.log("ConfirmPage, route.snapshot: ", this.route.snapshot);

    let a = this.route.snapshot.queryParams["action"] || "";
    let returnUri = this.route.snapshot.queryParams["return-uri"];

    if(this.bas.envtest) console.log("ConfirmPage, returnUri: " + returnUri);

    let parts = a.split("_");
    if (parts.length < 3) {
      // web.tools.confirmUser.paramLengthError
      this.data.error = "web.tools.confirmUser.paramLengthError";

    } else {
      this.data = {
        error: ""
      }
    }
    

  }



  ngOnInit() {

  }

  async confirm() {

    let action:string = this.route.snapshot.queryParams["action"];

    this.data.loading = true;
    let json = await this.bas.ws.json({
      aType: "appAdmin", 
      action: "confirmUser", 
      cuaction: action, 
      platform: this.route.snapshot.queryParams["platform"]
    });

    this.data.loading = false;

    if (json.success) {
      this.data.bttoken = json.accessToken;
      if (action.startsWith("confirmUserLp")) {

        let controls: DynformControl[] = [];

        controls.push(new DynformControl({ 
          key: 'passwordNew',
          placeholder: 'app.lib.pages.user.confirm.passwordNew',  
          type: "password",
          required: true,
          data: {
            min: 9
          }
        }));
        
        controls.push(new DynformControl({ 
          key: 'passwordConfirm',
          placeholder: 'app.lib.pages.user.confirm.passwordConfirm',  
          type: "password",
          required: true,
          data: {
            min: 9
          }
        }));

        
        this.bas.fs.updateFormObj(this.edit, controls, { });
    

        this.step++;
      } else {
        
        this.step += 2;
      }
    } else {
      // Redir til forsiden med feilmelding.

      // this.bas.ui.error(this.bas.ui.actrans("app.lib.pages.user.confirm.invalidConfirmKey"), 10);
      this.gotoHome();
    }

  }

  async savePassword() {
    
    this.edit.error = "";

    let rv = this.bas.fs.getRawValue(this.edit.form, this.edit.controls);

    if (rv === false) return;

    if (rv.passwordNew != rv.passwordConfirm) {
      // 
      this.edit.error = "app.lib.pages.user.confirm.passwordsDoNotMatch"
      return;
    }

    this.data.loading = true;
    
    let json = await this.bas.ws.json(MiscUtil.extend({
      aType: "appAdmin", 
      action: "changePassword",
      bttoken: this.data.bttoken
    }, rv ));

    
    this.data.loading = false;

    if (json.success) {
      this.step++;
    }

    /*
  $("#pPasswordConfirm").clickOnEnter("#savePasswordButton");
    $cb.jsonPost({
      action: "changePassword",
      formContainer: "fcPassword",
      onValidate: function(params) {
        var errors = [];
        var passwordNew = params["passwordNew"];
        var passwordConfirm = params["passwordConfirm"];
        if (passwordNew != passwordConfirm) errors.push("<nit:message key="web.main.js.jp.register.passwordsDoNotMatch" eqm="true" />");
        return errors;
      },
      onSuccess: function(data, params) {
        $cb.hide($("#fcPassword"));
        $cb.show($("#pwChanged"));
        $cb.show($("#confirmedWrapper"));
      }
    });
    */
  }


  gotoHome() {

    let returnUri = this.route.snapshot.queryParams["return-uri"];

    if (returnUri) {
      // https://test.cloud-booking.net/runguviaferrata/tools.htm?action=confirmUserLp_71211_4Dj3o8UY008HwPdq2OD2sek93Zzws096&platform=cbsite&return_uri=https://www.booktech.no
      this.bas.us.redirect(returnUri);
      return;
    }

    this.bas.ui.router.navigate([ 
      this.bas.ui.getRouterPrefix() 
        // + '/user'
      ], { queryParams: { 
  
      }}
    );

  }



}

/* lib-modules.module.ts */
import { NgModule } from '@angular/core';

import { LibModulesWolcModule } from './lib-modules-wolc.module';

import { LibComponentsCoreModule } from './lib-components-core.module';



@NgModule({
  declarations: [

  ],
  imports: [
    LibModulesWolcModule,
    LibComponentsCoreModule,

  ],
  exports: [
    LibModulesWolcModule,
    LibComponentsCoreModule,
  ]
})
export class LibModulesModule {}

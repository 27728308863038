<!-- <p>bt-color-popover works!</p> -->

<!-- <nz-color-block nzSize="large"></nz-color-block> -->

<a 
  href="#" 
  [class]="'cp-button bt-size-' + size" 

  nz-button 
  nz-popover 
  [nzPopoverContent]="contentTemplate">
  <!-- 
  nzPopoverTrigger="click" -->

  <span 
    class="cp-color" 

    *ngFor="let hex of defaultColors"
    [style.backgroundColor]="hex"
    ></span>

</a>



<ng-template #contentTemplate>
  <div class="colors">
       
    <div 
      class="box-color blank" 
      [class.selected]="color == ''"
      (click)="onClick('')"
      ></div>
    <div 
      class="box-color" 
      [class.selected]="color == hex"
      *ngFor="let hex of presets"
      [style.backgroundColor]="hex"
      (click)="onClick(hex)"
      ></div>
  </div>
  <div class="pull-clear"></div>
</ng-template>

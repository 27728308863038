

<div *ngIf="bas.ds.login.success" class="text-center">
  {{ 'app.lib.common.loggedInAs' | actrans }} {{bas.ds.login.user?.username}}

  <div>
    <button nz-button nzType="default" nzSize="small" (click)="bas.api.logout()">
      <i nz-icon nzType="logout" nzTheme="outline"></i> {{ 'app.lib.common.logout' | actrans }}
    </button>
  </div>


</div>

<div *ngIf="!bas.ds.login.success">
 
  

  <form nz-form [formGroup]="loginForm" class="login-form" (ngSubmit)="submitForm()" [nzAutoTips]="bas.fs.autoTips" >
    <p>
      {{ 'app.lib.common.loginBelow' | actrans }}
    </p>
    <nz-form-item>
      <nz-form-control >
        <nz-input-group nzPrefixIcon="user">
          <input type="text" nz-input formControlName="username" [placeholder]="'app.lib.common.login.username' | actrans | str" />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control   >
        <nz-input-group nzPrefixIcon="lock">
          <input type="password" nz-input formControlName="password" [placeholder]="'common.password' | actrans | str" />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <div nz-row class="login-form-margin">
      <div nz-col [nzSpan]="12">
        <label nz-checkbox formControlName="save">
          <span>{{ 'app.lib.common.login.save' | actrans }}!</span>
        </label>
      </div>
      <div nz-col [nzSpan]="12">
        <a class="login-form-forgot" (click)="lostPassword()">{{ 'app.lib.common.lostPassword' | actrans }}</a>
      </div>
    </div>
    <button nz-button class="login-form-button login-form-margin" [nzType]="'primary'">
      {{ 'app.lib.common.login' | actrans }}
    </button>
  </form>
</div>

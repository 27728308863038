import { Component, Input, OnInit } from '@angular/core';
import { MiscUtil } from '../../../util/misc.util';

import { DATAID } from '../../../services/data.service';


import { inject } from '@angular/core';

import { LibModulesWolcModule } from '../../../components/lib-modules-wolc.module';
import { BooktechAppService } from "../../../services/booktech-app.service";

import { LoginCheckComponent } from "../login-check/login-check.component";
import { UserMenuComponent } from "../user-menu/user-menu.component";
import { PageWrapperComponent } from "../../ui/page-wrapper/page-wrapper.component";

@Component({
  selector: 'btlib-user-page-wrapper',
  standalone: true,
  imports: [ 
    LibModulesWolcModule,
    LoginCheckComponent,
    UserMenuComponent,
    PageWrapperComponent,
  ],
  templateUrl: './user-page-wrapper.component.html',
  styleUrl: './user-page-wrapper.component.scss'
})
export class UserPageWrapperComponent {
  bas = inject(BooktechAppService);

  @Input()
  showMenu:boolean = true;
  @Input()
  loginCheck:boolean = true;
  
  
  constructor() {
  
    
  }

  ngOnInit(): void {
  }


}

<div class="bt-max-width-320-off">
  <div  *ngIf="!bas.ds.login.success">

    <div  *ngIf="!showLogin">
      <p class="ion-text-center ion-margin-top">{{ 'app.cbapi5.component.loginCheck.loginRequired' | actrans }} </p>
      <!-- <ion-icon name="heart" ></ion-icon> -->
      <p><button nz-button (click)="showLogin = true;">{{ 'app.lib.common.login' | actrans }}</button></p>
         
    </div>
  
    <div  *ngIf="showLogin">
      <btlib-login></btlib-login>
    </div>
   
  </div>
  <div  *ngIf="bas.ds.login.success && rolesRequired.length">
    <div  *ngIf="rolesRequired.indexOf(bas.ds.login.user.roleEnum) < 0">
      <p>{{ 'app.lib.common.loggedInAs' | actrans }} {{ bas.ds.login.user.username }}</p> 
      <p>{{ 'app.lib.common.login.vaildRole' | actrans }}</p>
  
      <p><button nz-button (click)="bas.api.logout()">{{ 'app.lib.common.logout' | actrans }}</button></p>
      
    </div>
  </div>
</div>


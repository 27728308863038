
<nz-modal 
  [nzVisible]="!!edit.obj" 
  [nzTitle]="'web.ajax.password' | actrans | str" 

  (nzOnCancel)="onCancel()" 
  (nzOnOk)="onOk()"
  >
  <!--  nzWidth="90vw" -->
  <ng-container *nzModalContent>
    
    <div *ngIf="edit.obj">

      <form nz-form nzLayout="vertical" [nzAutoTips]="bas.fs.autoTips">
        
        <btlib-form-row  [controls]="[ edit.controls.passwordOld ]" [form]="edit.form"  [grid]="bas.ui.gridFull" (change)="onChange($event)" ></btlib-form-row> 
        <btlib-form-row  [controls]="[ edit.controls.passwordNew ]" [form]="edit.form"  [grid]="bas.ui.gridFull" (change)="onChange($event)" ></btlib-form-row> 
        <btlib-form-row  [controls]="[ edit.controls.passwordConfirm ]" [form]="edit.form"  [grid]="bas.ui.gridFull" (change)="onChange($event)" ></btlib-form-row> 
    
      </form>
    </div>

  </ng-container>
  <!-- <div *nzModalFooter>
    <button 
      nz-button 
      nzType="default" 
      nzDanger 
      (click)="deleteOrder()"
      *ngIf="order.id && order.status && order.status.isAvailability"
      >
      {{ 'common.delete' | actrans }}  {{ 'common.booking._order' | actrans | str | lowercase }}
    </button>
    <button nz-button nzType="primary" (click)="closeOrderModal()">{{ 'common.close' | actrans }}</button>
  </div> -->
</nz-modal>


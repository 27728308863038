import { StorageInterface } from "./storage-interface";

export class StorageShim implements StorageInterface {
  constructor(private storage:Storage) {

  }
  get(key: string): Promise<string|null> {
    return new Promise<string|null>((res, rej) => {
      res(this.storage.getItem(key))
    });
 
  }
  /**
   * Set the value in preferences for a given key.
   */
  set(key: string, value:string): Promise<void> {
    return new Promise<void>((res, rej) => {
      this.storage.setItem(key, value);
      res();
    });
 
  }
  /**
   * Remove the value from preferences for a given key, if any.
   */
  remove(key: string): Promise<void> {
    return new Promise<void>((res, rej) => {
      this.storage.removeItem(key);
      res();
    });
  }
  /**
   * Clear keys and values from preferences.
   */
  clear(): Promise<void> {
    return new Promise<void>((res, rej) => {
      this.storage.clear();
      res();
    });
  }
}

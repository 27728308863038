import { Component, Input, OnInit } from '@angular/core';
import { MiscUtil } from '../../../util/misc.util';

import { DATAID } from '../../../services/data.service';

import { inject } from '@angular/core';

import { LibModulesWolcModule } from '../../../components/lib-modules-wolc.module';
import { BooktechAppService } from "../../../services/booktech-app.service";


@Component({
  selector: 'bt-page-wrapper',
  standalone: true,
  imports: [ LibModulesWolcModule ],
  templateUrl: './page-wrapper.component.html',
  styleUrl: './page-wrapper.component.scss'
})
export class PageWrapperComponent {
  bas = inject(BooktechAppService);


  // @Input()
  // showMenu:boolean = true;

  operatingMessages:any = "";
  
  constructor() {
  
    this.bas.ds.find(DATAID.APP_WEB_OPERATING_MESSAGES).subscribe((oms:any) => {
      // console.log("APP_WEB_OPERATING_MESSAGES: ", oms);
      this.operatingMessages = oms.message || "";
    })
    
  }

  ngOnInit(): void {

  }


}





<div class="page-wrapper-container">
  <div class="m-d" *ngIf="operatingMessages">
    <nz-alert nzBanner nzType="info" [nzMessage]="messageTemplate" nzShowIcon></nz-alert>
    <ng-template #messageTemplate>
      <div [innerHTML]="operatingMessages"></div> 
    </ng-template>
    
  </div>
  <!-- <nz-alert nzType="info" nzMessage="{{ operatingMessages }}" nzShowIcon></nz-alert> -->
  <!-- <div *ngIf="operatingMessages" [innerHTML]="operatingMessages"></div> -->
  <ng-content></ng-content>
</div>



import { Component, Input, OnInit, Injector, EventEmitter, Output } from '@angular/core';

import { MiscUtil } from '../../../util/misc.util';
import { DynformControl } from '../../../model/dymform-control';
import { UntypedFormGroup } from '@angular/forms';

import { inject } from '@angular/core';

import { LibModulesWolcModule } from '../../../components/lib-modules-wolc.module';
import { BooktechAppService } from "../../../services/booktech-app.service";

import { BtDynamicFormControlComponent } from '../bt-dynamic-form-control/bt-dynamic-form-control.component' 


@Component({
  selector: 'bt-message-field',
  standalone: true,
  imports: [ LibModulesWolcModule, BtDynamicFormControlComponent ],
  templateUrl: './bt-message-field.component.html',
  styleUrl: './bt-message-field.component.scss'
})
export class BtMessageFieldComponent {
  bas = inject(BooktechAppService);


  @Input() form: UntypedFormGroup|null = null;
  @Input() control!: DynformControl;

  @Output() change = new EventEmitter<any>();
  
  constructor() {
    
  }

  fg():UntypedFormGroup {
    return (this.control.fg || this.form) as UntypedFormGroup;
  }

  ngOnInit(): void {
    // if(this.bas.envtest) console.log("BtMessageFieldComponent.ngOnInit, control: ", this.control);
  }

  onChange(event:any) {
    this.change.emit(event);
    
  }


}



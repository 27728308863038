<div nz-row [nzGutter]="grid.gutter" nzAlign="middle">
  <div 
    *ngFor="let col of cols || []" 
    [class.bt-row-hide]="col && !col.show()" 
    nz-col 
    [nzXs]="col.grid?.xs || grid.xs" 
    [nzSm]="col.grid?.sm || grid.sm" 
    [nzMd]="col.grid?.md || grid.md" 
    [nzLg]="col.grid?.lg || grid.lg" 
    [nzXl]="col.grid?.xl || grid.xl" 
    [nzXXl]="col.grid?.xxl || grid.xxl"
    >
    
    <nz-form-item>
      <nz-form-label *ngIf="col.label">
        <span class="text-bold">{{ col.label | actrans }}</span>

        <!-- <i 
          class="mkInfoIcon"
          *ngIf="col.mkInfo"
          nz-icon [nzType]="'info-circle'" 
          nz-tooltip 
          [nzTooltipTitle]="mkInfoTemplate" 
          >
          <ng-template #mkInfoTemplate>
            <span class="text-sm" [innerHtml]="col.mkInfo | actrans"></span>
          </ng-template>
        </i> -->
        
      </nz-form-label>
      <nz-form-control [class]="col.css || ''">
      
        <div *ngIf="col.value">

          <ng-container [ngSwitch]="col.type">
            <div *ngSwitchCase="'json'">
              <pre>{{ col.value}}</pre>
            </div>
        
            <label *ngSwitchDefault [innerHtml]="col.value | trusthtml" >
            
            </label>
          
          </ng-container>
        </div>
      
      </nz-form-control>
    </nz-form-item>

  </div>
</div>

import { Pipe, PipeTransform, inject } from '@angular/core';
import { BooktechAppService } from "../services/booktech-app.service";



/*
*/
@Pipe({name: 'int', standalone: true })
export class IntPipe implements PipeTransform {


  transform(input: any): number {
    if (input === undefined) return 0;
    let int = parseInt(input);
    return int ? int : 0;
  }
}

import { Pipe, PipeTransform, inject } from '@angular/core';
import { BooktechAppService } from "../services/booktech-app.service";



@Pipe({ name: "nfi", standalone: true })
export class NfiPipe implements PipeTransform {
  bas = inject(BooktechAppService);
  


  transform(value: number | string, opts:any = { }): string {
    opts = opts || { };

    let val = typeof value === "string" ? this.bas.ui.nfparse(value) : value;
    if (val !== undefined) val /= 100.0;

    return this.bas.ui.nf(val, opts.fractionSize);

  }

  // parse(value: string, fractionSize: number = 2): number {
  //   return this.bas.ui.nfparse(value, fractionSize);

  // }

}


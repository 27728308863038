import { Component, inject } from '@angular/core';

import { LibModulesWolcModule } from '../../components/lib-modules-wolc.module';
import { BooktechAppService } from "../../services/booktech-app.service";


@Component({
  selector: 'lib-template-component',
  standalone: true,
  imports: [ LibModulesWolcModule ],
  templateUrl: './lib-template-component.component.html',
  styleUrl: './lib-template-component.component.scss'
})
export class LibTemplateComponentComponent {
  bas = inject(BooktechAppService);

  constructor() {
    // if (this.bas.envtest) console.log("LibTemplateComponentComponent.constructor")
  }

  ngOnInit() {
    // if (this.bas.envtest) console.log("LibTemplateComponentComponent.ngOnInit")

  }

}




<a 
  href="#" 
  [class]="'cp-button bt-size-' + size" 

  nz-button 
  nz-popover 
  [nzPopoverContent]="contentTemplate"
  (click)="$event.preventDefault()"
  >
  <!-- 
  nzPopoverTrigger="click" -->

  <span 
    [class]="'cp-style ' + style" 

    *ngFor="let style of defaultStyles"
    
    ></span>

</a>



<ng-template #contentTemplate>
  <div class="colors">
       
    <div 
      [class]="'box-style bts-none'" 
      [class.selected]="style == 'bts-none'"
      (click)="onClick('bts-none')"
    ></div>

    <div 
      [class]="'box-style ' + item" 
      [class.selected]="style == item"
      *ngFor="let item of presets"
      (click)="onClick(item)"
      ></div>
  </div>
  <div class="pull-clear"></div>
</ng-template>

import { Pipe, PipeTransform, inject } from '@angular/core';
import { BooktechAppService } from "../services/booktech-app.service";

import { DomSanitizer } from '@angular/platform-browser';

/*
*/
@Pipe({name: 'trusthtml', standalone: true})
export class TrustHtmlPipe implements PipeTransform {
  bas = inject(BooktechAppService);
  
  constructor(public sanitizer: DomSanitizer) {

  }

  transform(value: any): any {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}

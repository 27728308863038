
<pinch-zoom disableZoomControl="disable" [limitPan]="true" backgroundColor="transparent">
  <div class="image-map-container" [ngStyle]="getContainerStyle()">
    <img [src]="bg.img" />
    <div class="image-map-item-container">
      <div 
        class="map-item" 
        [class.disabled]="item.disabled"
        [ngStyle]="getItemStyle(item)"
        [title]="item.name" 
        *ngFor="let item of items" 
        (click)="onItemClick(item)" 
   
        ></div>
    
    </div>
  </div>
  </pinch-zoom>
  
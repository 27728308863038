
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { inject } from '@angular/core';

import { LibModulesWolcModule } from '../../../components/lib-modules-wolc.module';
import { BooktechAppService } from "../../../services/booktech-app.service";


@Component({
  selector: 'bt-color-popover',
  standalone: true,
  imports: [ LibModulesWolcModule ],
  templateUrl: './bt-color-popover.component.html',
  styleUrl: './bt-color-popover.component.scss'
})
export class BtColorPopoverComponent {
  bas = inject(BooktechAppService);

  defaultColors = [
    "#00A560",
    "#FFDE17",
    "#F68121",
    "#ED1C24"
  ];


  @Input('presets') presets: string[] = this.defaultColors;

  @Input('size') size: string = "default";
  @Input('color') color: string = "";
  @Output() change = new EventEmitter<any>();
  
  constructor() {

  }

  ngOnInit(): void {
    
  }

  onClick(color:string) {
    this.color = color;
    this.change.next(color);
  }

}




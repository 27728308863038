
export const lowerCase = "abcdefghijklmnopqrstuvwxyz";
export const upperCase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
export const numeric 	= "0123456789";
export const alphabet  = lowerCase + upperCase + numeric;

// Sunday - Saturday : 0 - 6
export const WEEKDAY_SUNDAY 	  = 0;
export const WEEKDAY_MONDAY 	  = 1;
export const WEEKDAY_TUESDAY 	  = 2;
export const WEEKDAY_WEDNESDAY 	= 3; 
export const WEEKDAY_THURSDAY 	= 4;
export const WEEKDAY_FRIDAY 	  = 5;
export const WEEKDAY_SATURDAY 	= 6;

export const Colors = {
  "blue": 			"#0166B4",
  "green": 			"#00A560",
  "red": 				"#ED1C24",
  "purple": 			"#5C2E91",
  "orange": 			"#F68121",
  
  "purple-blue": 		"#21409B",
  "yellow-orange": 	"#F9A61C",
  "orange-red": 		"#EE3E3E",
  "blue-green": 		"#01AAAD",
  "red-purple": 		"#A3248F",
  "green-yellow": 	"#70BE44",
  "yellow": 			"#FFDE17",
  
  "gray-10": 			"hsl(0, 0%, 10%)",
  "gray-20": 			"hsl(0, 0%, 20%)",
  "gray-30": 			"hsl(0, 0%, 30%)",
  "gray-40": 			"hsl(0, 0%, 40%)",
  "gray-50": 			"hsl(0, 0%, 50%)",
  "gray-60": 			"hsl(0, 0%, 60%)",
  "gray-70": 			"hsl(0, 0%, 70%)",
  "gray-80": 			"hsl(0, 0%, 80%)",
  "gray-90": 			"hsl(0, 0%, 90%)",
  "white": 			"white",
  "gray": 			"gray",
  "black": 			"black"
};

export const ColorClassBg = [
  "bgc-blue", 
  "bgc-green",	
  "bgc-red",
  "bgc-purple",
  "bgc-orange",	
  
  "bgc-purple-blue", 
  "bgc-yellow-orange",	
  "bgc-orange-red",
  "bgc-blue-green",
  "bgc-red-purple",	
  "bgc-green-yellow",
  "bgc-yellow"
];

export const ColorClassFg = [
  "fgc-blue", 
  "fgc-green",	
  "fgc-red",
  "fgc-purple",
  "fgc-orange",	
  
  "fgc-purple-blue", 
  "fgc-yellow-orange",	
  "fgc-orange-red",
  "fgc-blue-green",
  "fgc-red-purple",	
  "fgc-green-yellow",
  "fgc-yellow"
];


export const PriceRule = {
  Price 					        : { id: 0,	name: "Price", 						mkName: "bus.priceRule.name.price", 					mkDesc: "bus.priceRule.desc.price" },
  PriceCount 				      : { id: 1,	name: "PriceCount", 				mkName: "bus.priceRule.name.priceCount", 				mkDesc: "bus.priceRule.desc.priceCount" },
  PriceDays 				      : { id: 2,	name: "PriceDays",					mkName: "bus.priceRule.name.priceDays", 				mkDesc: "bus.priceRule.desc.priceDays" },
  PriceGuests 			      : { id: 3,	name: "PriceGuests", 				mkName: "bus.priceRule.name.priceGuests", 				mkDesc: "bus.priceRule.desc.priceGuests" },
  
  PriceCountDays 			    : { id: 4,	name: "PriceCountDays", 			mkName: "bus.priceRule.name.priceCountDays", 			mkDesc: "bus.priceRule.desc.priceCountDays" },
  PriceCountGuests 		    : { id: 5,	name: "PriceCountGuests", 			mkName: "bus.priceRule.name.priceCountGuests", 			mkDesc: "bus.priceRule.desc.priceCountGuests" },
  PriceDaysGuests 		    : { id: 6,	name: "PriceDaysGuests", 			mkName: "bus.priceRule.name.priceDaysGuests", 			mkDesc: "bus.priceRule.desc.priceDaysGuests" },
  
  PriceCountDaysGuests 	  : { id: 7,	name: "PriceCountDaysGuests", 		mkName: "bus.priceRule.name.priceCountDaysGuests", 		mkDesc: "bus.priceRule.desc.priceCountDaysGuests" },
  
  PriceHours 				      : { id: 8,	name: "PriceHours", 				mkName: "bus.priceRule.name.priceHours", 				mkDesc: "bus.priceRule.desc.priceHours" },
  PriceHoursCount 		    : { id: 9,	name: "PriceHoursCount", 			mkName: "bus.priceRule.name.priceHoursCount", 			mkDesc: "bus.priceRule.desc.priceHoursCount" },
  PriceHoursGuests 		    : { id: 10,	name: "PriceHoursGuests", 			mkName: "bus.priceRule.name.priceHoursGuests", 			mkDesc: "bus.priceRule.desc.priceHoursGuests" },
  PriceHoursCountGuests 	: { id: 11,	name: "PriceHoursCountGuests", 		mkName: "bus.priceRule.name.priceHoursCountGuests", 	mkDesc: "bus.priceRule.desc.priceHoursCountGuests" },
  
  PriceMinutes 			      : { id: 12,	name: "PriceMinutes", 				mkName: "bus.priceRule.name.priceMinutes", 				mkDesc: "bus.priceRule.desc.priceMinutes" },
  PriceMinutesCount 		  : { id: 13,	name: "PriceMinutesCount", 			mkName: "bus.priceRule.name.priceMinutesCount", 		mkDesc: "bus.priceRule.desc.priceMinutesCount" },
  PriceMinutesGuests 		  : { id: 14,	name: "PriceMinutesGuests", 		mkName: "bus.priceRule.name.priceMinutesGuests", 		mkDesc: "bus.priceRule.desc.priceMinutesGuests" },
  PriceMinutesCountGuests : { id: 15,	name: "PriceMinutesCountGuests", 	mkName: "bus.priceRule.name.priceMinutesCountGuests", 	mkDesc: "bus.priceRule.desc.priceMinutesCountGuests" },
};



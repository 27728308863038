/* lib-modules-wolc.module.ts */
import { NgModule } from '@angular/core';

import { LibModulesCommonModule } from './lib-modules-common.module';
import { LibModulesNgZorroModule } from './lib-modules-ng-zorro.module';
import { BtPipesModule } from '../pipes/bt-pipes.module';


@NgModule({
  declarations: [

  ],
  imports: [
    LibModulesCommonModule,
    LibModulesNgZorroModule,
    BtPipesModule,
  

  ],
  exports: [
    LibModulesCommonModule,
    LibModulesNgZorroModule,
    BtPipesModule,


  ]
})
export class LibModulesWolcModule {}

import { CanDeactivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { BooktechAppService } from '../services/booktech-app.service';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}


export const pendingChangesGuard: CanDeactivateFn<ComponentCanDeactivate> = (component, currentRoute, currentState, nextState) => {

  if (component.canDeactivate()) return true;

  let bas = inject(BooktechAppService);

  confirm(bas.ui.actrans("web.common.product.leaveWarning"));

  return true;
};

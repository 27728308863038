

<nz-form-item>
  <nz-form-label class="" nzFor="" *ngIf="!opts.hideLabel">{{ 'bus.booking.guests' | actrans }}</nz-form-label>
  <nz-form-control>

    <button 
      nz-button 
      nzBlock 
      [nzSize]="view.size"
      nz-dropdown 
      nzTrigger="click"  
      [nzDropdownMenu]="guestsPopup" 
      [(nzVisible)]="guestsPopupVisible"
      (nzVisibleChange)="guestsPopupVisibleChange($event)" 
      class="text-left"
      style=""
      >
      <span nz-icon nzType="user"></span>
      <span class="text-lg-off"> {{ getGcs() }}</span>

    </button>
    <nz-dropdown-menu #guestsPopup="nzDropdownMenu">

      <div nz-menu>
        <i nz-icon nzType="close" class="pull-right p-3" (click)="guestsPopupClose()" ></i> 

        <div class="p-3">

            <div *ngFor="let cat of categories; index as gcIdx">
              <div class="grid grid-cols-2 gap-4" *ngIf="cat.max !== 0">
                <div> <nz-form-label  [nzFor]="'gc' + gcIdx">{{ cat.mk | actrans }}</nz-form-label></div>
                <div>
                  <nz-space [nzSize]="'small'">
                    <button 
                      *nzSpaceItem 
                      nz-button 
                      nzType="primary" 
                      nzShape="circle" 
                      (click)="decrementGcValue(gcIdx)" >
                        <i nz-icon nzType="minus-circle"></i>
                    </button>

                    <input 
                      *nzSpaceItem 
                      nz-input 
                      [(ngModel)]="gcs.map[gcIdx]" 
                      (ngModelChange)="onGcChange(gcIdx)"
                      [name]="'gc' + gcIdx" 
                      class="text-center" 
                      style="width: 50px;" />

                    <button 
                      *nzSpaceItem 
                      nz-button 
                      nzType="primary" 
                      nzShape="circle" 
                      (click)="incrementGcValue(gcIdx)" ><i nz-icon nzType="plus-circle"></i></button>
                  </nz-space>

                </div>
              </div>

            </div>
          

        </div>
      </div>

    </nz-dropdown-menu>
  </nz-form-control>
</nz-form-item>
    
/* lib-components-image-map.modules.ts */
import { NgModule } from '@angular/core';


import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';


// img-map
import { ImageMapComponent } from './feature/image-map/image-map.component' 



// --------------

@NgModule({
  declarations: [

  ],
  imports: [
    PinchZoomModule,
    ImageMapComponent

  ],
  exports: [
    PinchZoomModule,
    ImageMapComponent

  ]
})
export class LibComponentsImageMapModule {}

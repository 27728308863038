import { Component, Input, OnInit } from '@angular/core';

import { DATAID } from '../../../services/data.service';

import { inject } from '@angular/core';

import { LibModulesWolcModule } from '../../../components/lib-modules-wolc.module';
import { BooktechAppService } from "../../../services/booktech-app.service";


import { LoginComponent } from "../login/login.component";

@Component({
  selector: 'btlib-login-check',
  standalone: true,
  imports: [ LibModulesWolcModule, LoginComponent ],
  templateUrl: './login-check.component.html',
  styleUrl: './login-check.component.scss'
})
export class LoginCheckComponent {
  bas = inject(BooktechAppService);


  @Input() rolesRequired:string[] = [];

  showLogin = true;
  constructor() {

  }

  ngOnInit(): void {
  }

}

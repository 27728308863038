


<div style="">


  <nz-table 
    #dataTable 

    [nzData]="d.current || []"
    [nzPageSize]="1000"
    [nzShowPagination]="false"
    [nzLoading]="d.loading"
    nzSize="small"
    class="nz-table-xs"

    >

    <thead>
      <tr>
      
        <th 
          *ngFor="let h of d.header || []; index as i"
          [nzAlign]="findAlign(h.align)"
          [nzSortFn]="h.disableSort ? undefined : sortFns[i]" 
          [nzWidth]="h.width"
          >
          {{ h.text }}
        </th>

      </tr>

    </thead>
    <tbody>
      <tr *ngFor="let row of dataTable.data" >

        <td 
        *ngFor="let c of row.items || []; index as i"
        [nzAlign]="findAlign(d.header[i].align)"
        [class]="c.css"
        >
          <span [ngSwitch]="d.header[i].customType">
            <span  *ngSwitchCase="'bid'">
   
              <a
              [href]="bas.ui.getRouterPrefix() + '/lib/goto?goto-action=order&goto-id='+c.text"
              target="_blank"
              [title]="'common.edit' | actrans" >
                {{ c.text }}
              </a>
<!--
              (click)="$event.preventDefault(); openOrder(c.text)"-->
            </span>
            <span  *ngSwitchCase="'button'">

              <button 
                nz-button 
                nzType="default" 
                nzSize="small"
                (click)="$event.preventDefault(); miscStatsAction(c, d.header[i])"
                [title]="c.title"
              > 
                <span *ngIf="c.icon" nz-icon [nzType]="c.icon" nzTheme="outline"></span>
                <span *ngIf="c.text">{{ c.text | actrans }}  </span>
              </button>

            </span>
            <span  *ngSwitchDefault [innerHtml]="bas.ui.fixNewline( c.text )">
              
            </span>
          </span>
          
        </td>




      </tr>
      <tr>
        <td colspan="20">&nbsp;</td>
      </tr>
    </tbody>
    <tfoot *ngIf="d.footer?.length">
      <tr>
      
        <th 
          *ngFor="let f of d.footer || []; index as i"
          [nzAlign]="d.header[i].align == 'right' ? 'right' : null"
          >
          {{ f.text }}
        </th>

      </tr>
    </tfoot>
  </nz-table>




</div>

  
<nz-modal 
  [(nzVisible)]="msad.visible" 
  [nzTitle]="msad.cellHeader?.customTypeName || ('common.info' | actrans | str)" 
  nzWidth="90vw" 
  (nzOnCancel)="msad.visible = false;" 
  (nzOnOk)="msad.visible = false;"  
  [nzOkText]="'common.close' | actrans | str"
  [nzCancelText]="null" > 
  <ng-container *nzModalContent>
    <div class="table-responsive-wrapper"> 
      <bt-table-data [data]="msad"></bt-table-data>
    </div>

  </ng-container>
</nz-modal>


import { Pipe, PipeTransform, inject } from '@angular/core';
import { BooktechAppService } from "../services/booktech-app.service";

/*

*/
@Pipe({name: 'join', standalone: true })
export class JoinPipe implements PipeTransform {



  transform(input: any, character: string = '', opts:any = { } ): string {
    if (!Array.isArray(input)) {
      return input;
    }

    let chars = character == "\n" ? "<br/>" : character;
    let field = opts?.field;

    if (field) {

      return input.map((obj:any) => obj[field]).join(chars);
    }
    return input.join(chars);
  }
}

import { Component, Input, OnInit, Injector, EventEmitter, Output } from '@angular/core';

import { inject } from '@angular/core';

import { LibModulesWolcModule } from '../../../components/lib-modules-wolc.module';
import { BooktechAppService } from "../../../services/booktech-app.service";


const COL_COUNT = 3;

@Component({
  selector: 'bt-row-3',
  standalone: true,
  imports: [ LibModulesWolcModule ],
  templateUrl: './bt-row-3.component.html',
  styleUrl: './bt-row-3.component.scss'
})
export class BtRow3Component {

  @Input() grid: any = { };
  @Input() grids: any[] = [];
  @Input() opts: any = { };

  @Input() g: any[] = [];

  constructor() {

  }

  ngOnInit(): void {
    if (this.grids?.length) this.g = [...this.grids];
    if (this.g == undefined) this.g = [ ];

    if (this.g.length < COL_COUNT) {
      for (let i = this.g.length; i < COL_COUNT; i++) {
        this.g.push(this.grid || { })
      }
    }
    // MiscUtil.extend(this.g, this.grid);

  }

}



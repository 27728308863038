import { inject } from '@angular/core';

import { LibModulesWolcModule } from '../../../components/lib-modules-wolc.module';
import { BooktechAppService } from "../../../services/booktech-app.service";


import { Component, Input, OnInit, Injector, EventEmitter, Output } from '@angular/core';
//  
import { MiscUtil } from '../../../util/misc.util';
import { DynformControl } from '../../../model/dymform-control';
import { UntypedFormGroup } from '@angular/forms';


import { BtDynamicFormControlComponent } from '../bt-dynamic-form-control/bt-dynamic-form-control.component' 
import { BtMessageFieldComponent } from '../bt-message-field/bt-message-field.component';

@Component({
  selector: 'btlib-form-row',
  standalone: true,
  imports: [ 
    LibModulesWolcModule, 
    BtDynamicFormControlComponent,
    BtMessageFieldComponent 
  ],
  templateUrl: './bt-form-row.component.html',
  styleUrls: ['./bt-form-row.component.scss']
})
export class BtFormRowComponent implements OnInit {
  bas = inject(BooktechAppService);

  @Input() form!: UntypedFormGroup;
  // @Input() controls: DynformControl[] = [];
  @Input() options:any = { };

  allControls: DynformControl[] = [];
  // visibleControls: DynformControl[] = [];
  @Input()
  get controls(): DynformControl[] {return this.allControls;}
  set controls(value: DynformControl[]) {
    this.allControls = value;
    // console.log(value);
    // this.visibleControls = value.filter(dc => {
    //   if (dc == undefined) {
    //     console.log("dc == undefined");
    //   }
    //   return dc != undefined && dc.show(dc) 
    // });
  }
  
  @Input() grid: any = { };
  g = {
    xs: null,
    sm: null,
    md: null,
    lg: null,
    xl: null,
    xxl: null,
    gutter: 16
  };

  @Output() change = new EventEmitter<any>();
  
  constructor() {

  }

  ngOnInit(): void {
    MiscUtil.extend(this.g, this.grid);

  }

  onChange(event:any) {
    this.change.emit(event);
    
  }

}




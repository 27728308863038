import { inject } from '@angular/core';
import { BooktechAppService } from './booktech-app.service';
import { MiscUtil } from '../util/misc.util';
import { PriceRule } from '../util/const';



export class OrderService {

  constructor(public bas: BooktechAppService ) {
    if(this.bas.envtest) console.log("OrderService.constructor");

   
  }

  
  placeOrder(cart:any, extraParams:any, jsonData?:any):Promise<any> {
    
    var user = this.bas.ds.login.user;
    var params:any = { 
      actionType: "appService", 
      action: "placeOrder", 
      acceptEula: true
    };

    if (user) params.userId = user.id;

    MiscUtil.extend(params, extraParams);

    if (jsonData) params.jsonData = JSON.stringify(jsonData);


    MiscUtil.extend(params, MiscUtil.getCartParams(cart)); 

    if (this.bas.envtest) console.log("OrderService.placeOrder: params: ", params);

    return this.bas.ws.json(params, { showLoading: true }).then((json) => {
      if (this.bas.envtest) console.log("OrderService.placeOrder.then, ", json);
      
      if (json.success) {

        

      }

      return json;
    });
  }


  easyPay(id:number, payType:string, extraParams:any = { }):Promise<any> {
    var params:any = { 
      actionType: "order", 
      action: "easyPay", 
      id: id,
      payType: payType,
      url: this.bas.nossr ? location.href : ""
    };

    MiscUtil.extend(params, extraParams);

    if (this.bas.envtest) console.log("OrderService.easyPay: params: ", params);

    return this.bas.ws.json(params, { showLoading: true }).then((json) => {
      if (this.bas.envtest) console.log("OrderService.easyPay.then, ", json);
      
      if (json.success) {

        

      }

      return json;
    });
  }



  updatePwAmount (pw:any) {
    let priceRule = pw.priceRule || "PriceCount";  // PriceCountDaysGuests

    let start = this.bas.ui.dateParseIso(pw.startAsIso8601);
    let end = this.bas.ui.dateParseIso(pw.endAsIso8601);
    
    // if (this.bas.envtest) console.log("OrderService.updatePwAmount start, " + pw.startAsIso8601 + " -> " + start);
    // if (this.bas.envtest) console.log("OrderService.updatePwAmount end, " + pw.endAsIso8601 + " -> " + end);


    //static getPrice (priceRuleId:number|string, amountDouble:number, quantity:number, days?:number, start?:Date, end?:Date, guests?:number)
    let amount = MiscUtil.getPrice(priceRule, pw.unitAmount, pw.quantity, 1, start, end, pw.guestCounts?.count); // Setter days til 1 slik at vi ikke regner ut antall dager
    // if (this.bas.envtest) console.log("OrderService.updatePwAmount.amount, " + amount);
    if (amount === undefined) {

      return;
    }
    pw.amountDouble = amount;

  }

  getCartItems(items:any[] = []) {
    // let items:any[] = cart?.items || [];
    return items.map((pw:any) => {
      let item:any = { 
        product: pw.productId,
        periodAsRange: pw.periodAsRange,
        guestCountsAsString: pw.guestCountsAsString,
        customAmountDouble: pw.customAmountDouble,
        jsonData: pw.jsonData || { }
        
      }

      if (pw.accessories) {
        item.jsonData.accessories = { };
        pw.accessories.filter((acc:any) => !acc.findMandatory && acc.quantity).map((acc:any) => {
          item.jsonData.accessories[acc.productId] = {
            productId: acc.productId,
            quantity: acc.quantity
          }
        });
      }


      return item;
    }); 

  }


  updateAmount(pw:any) {
    let amount = pw.amountDouble;
    for (let acc of pw.accessoriesOptional) {
      amount += acc.amountDouble;
    }

    if (pw.groupItems?.length) {
      for (let gi of pw.groupItems) {
        amount += gi.amountDouble;
      }
    }
    pw.calcAmount = amount;
  }
    
  async updatePw(pw:any, opts:any = { }) {

    let ui = this.bas.ui;
    // let pw = this.pw ;
    if(this.bas.envtest) console.log("OrderService.updatePw, pw: ", pw);

    var params:any = {
      aType: "appService",
      action: "getPwCart",
      product: pw.productId
    }

    if (opts?.params) {
      MiscUtil.extend(params, opts?.params)
    }


    if (pw.productObj) {
      let p = pw.productObj;
      if (pw.productObj.tcTime || (pw.productObj.tcDate && pw.productObj.singleDateProduct)) {
        if (pw.startDateAsString) params.startDateAsString = pw.startDateAsString;
        if (pw.productObj.tcTime && pw.timePeriodAsString) params.timePeriodAsString = pw.timePeriodAsString;
  
      } else if (p.periodProduct) {
        if (pw.periodAsRange) params.periodAsRange = pw.periodAsRange;
     
      }
      
      if (p.guestProduct && pw.guestCounts) params.guestCountsAsString = pw.guestCounts.guestCountsAsString;

      if (p.findIsPriceCustom) params.customAmountDouble = pw.customAmountDouble;

      if (pw.type) {
        params.type = pw.type;
        // if (pw.type == "PROVIDER") params.provider = true;
        // else    if (pw.type == "OVERVIEW") params.overview = true;
      } 

     
    } else {

      if (pw.periodAsRange) params.periodAsRange = pw.periodAsRange;
      if (pw.guestCountsAsString) params.guestCountsAsString = pw.guestCountsAsString;
    }

   
    let res = await this.bas.ws.json(params, { });

    if(this.bas.envtest) console.log("OrderService.getPwCart: ", res);
    if (res.success) {
      // this.cart = res.cart;

      let pwPrev = pw;
      pw = res.cart.items[0];

      if (pw.availablePoolUnits) {
        pw.jsonData = pw.jsonData || { };
        let apu = pw.availablePoolUnits.find((i:any) => i.id == pwPrev.jsonData?.poolUnitId);
        pw.jsonData.poolUnitId = apu?.id || '';
      }

      let prod = pw.productObj;
      
      this.bas.acs.updateProductObj(prod);

      for (let childType of [ "accessories", "groupItems" ] ) {
        if (pwPrev[childType] === undefined) continue; 

        let childMap = MiscUtil.listToMap(pw[childType] || [], "productId"); 
        for (let childPrev of pwPrev[childType] || []) {
          let childNext = childMap[childPrev.productId];
          if (childNext && (childPrev.quantity != childNext?.quantity)) {
              
            if(this.bas.envtest) console.log("updating childNext, name: "+childNext.product+": " + childNext.quantity + " -> " + childPrev.quantity);
            childNext.quantity = childPrev.quantity;
            this.bas.os.updatePwAmount(childNext);
            
          }
        }
      }
 
  

      pw.startDateAsJsDate = this.bas.ui.stringToDate(pw.startDateAsString);
      pw.endDateAsJsDate = this.bas.ui.stringToDate(pw.endDateAsString);
      pw.periodArray =[  pw.startDateAsJsDate,  pw.endDateAsJsDate ]

      // if(this.bas.envtest) console.log("OrderService.getPwCart.qty: ", pw.quantity);
      
      for (let gcIdx in pw.guestCategories) {
        if (pw.guestCounts.map[gcIdx] === undefined) pw.guestCounts.map[gcIdx] = 0; 
      }

      
			let errorMsg = "";
			
      if (pw) {
        if (pw.isStartBeforeStartDateThresholdMin) errorMsg = ui.actrans("web.servlet.json.updatePrice.startDateThresholdMin", [pw.startDateThresholdMin]);
				else if (pw.isStartAfterStartDateThresholdMax) errorMsg = ui.actrans("web.servlet.json.updatePrice.startDateThresholdMax", [pw.startDateThresholdMax]);
				else if (pw.isGuestCountLessThenMin) errorMsg = ui.actrans("web.servlet.json.updatePrice.guestCountLessThenMin", [prod.findMinGuests]);
				else if (pw.isGuestCountMoreThenMax) errorMsg = ui.actrans("web.servlet.json.updatePrice.guestCountMoreThenMax", [prod.findMaxGuests]);
				else if (prod.timeProduct && !prod.hasTimes) errorMsg = ui.actrans("web.servlet.json.updatePrice.noTimeAvailable"); 
				else if (!pw.isAvailable || !pw.isOrderable) {
//					if (prod.isPricesEmpty && !prod.packageProduct) {
//						errorMsg = "NULL"
//					} else 
					
						if (
              !prod.productConfig.isRoute &&
              prod.availabilityInfo &&
              prod.findTypeExperience 
              && pw.selectableDatesNext !== undefined 
              && pw.selectableDatesNext.length == 0
            
            ) {
              errorMsg = ui.actrans("web.servlet.json.updatePrice.soldOut"); 
              pw.isSoldOut = true;
            
            } else {

              
                errorMsg = ui.actrans("web.servlet.json.updatePrice.notAvailable"); 
                
            
            }
		
				}
//				else if (!pw.isOrderable) errorMsg = ui.actrans("web.servlet.json.updatePrice.notAvailable"); 
				
				
				if (errorMsg == "" && !prod.timeProduct) {
					
					if (prod.findTypeLodging && pw.guestCounts.count > pw.cap) errorMsg = ui.actrans("web.servlet.json.updatePrice.cap");
					else if (pw.isLessThenMinDaysBooking) errorMsg = ui.actrans("web.servlet.json.updatePrice.lessThenMinDaysBooking", [pw.minDaysBooking]);
          else if (pw.isMoreThenMaxDaysBooking) errorMsg = ui.actrans("web.servlet.json.updatePrice.moreThenMaxDaysBooking", [pw.maxDaysBooking]);
					else if (pw.totalAmount === "") errorMsg = ui.actrans("web.servlet.json.updatePrice.priceIsNull");
					
				}
			} else errorMsg = ui.actrans("web.servlet.json.updatePrice.priceIsNull");
			
			if (errorMsg == "" && pw.findMkError != "") errorMsg = pw.findMkError;
			
			if (errorMsg == "NULL") errorMsg = "";
			
			// if (errorMsg != "" && !p.availabilityInfo && aiContainer.length) errorMsg = "";
			
      pw.errorMsg = errorMsg;
			if (errorMsg != "") {

      }
 
      res.pw = pw;
      return res;
    }

    // Feilmelding
    res.pw = pw;
    return res;
  }



}

import { Pipe, PipeTransform, inject } from '@angular/core';
import { BooktechAppService } from "../services/booktech-app.service";

import { SafeHtml } from "@angular/platform-browser";



/*
*/
@Pipe({name: 'actrans', standalone: true })
export class ACTransPipe implements PipeTransform {

  bas = inject(BooktechAppService);
  


  // 'key.test' | actrams:[]:true:'default text'
  transform(key: any, params:any|any[] = [ ], safe: boolean = false, fallback?:string):  (string | SafeHtml) {
    //Log.l("value.type: " + typeof value + ", value: ", value);
    
    return this.bas.ui.actrans(key, params, safe, fallback);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { MiscUtil } from '../../../util/misc.util';

import { DATAID } from '../../../services/data.service';

import { inject } from '@angular/core';

import { LibModulesWolcModule } from '../../../components/lib-modules-wolc.module';
import { BooktechAppService } from "../../../services/booktech-app.service";

import { LoginCheckComponent } from "../login-check/login-check.component";

@Component({
  selector: 'btlib-user-menu',
  standalone: true,
  imports: [ 
    LibModulesWolcModule, 
    LoginCheckComponent 
  ],
  templateUrl: './user-menu.component.html',
  styleUrl: './user-menu.component.scss'
})
export class UserMenuComponent {
  bas = inject(BooktechAppService);

  menuDrawerVisible:boolean = false;

  company:any = { };


  constructor() {
    // console.log("MenuMainComponent, pw: ", this.pw);
    if(this.bas.envtest) console.log("UserMenuComponent, config: ", this.bas.ds.config);
    this.company = this.bas.ds.config.company;

  }

  ngOnInit(): void {

  }

  drawerOpen(): void {
    this.menuDrawerVisible = true;
  }

  drawerClose(): void {
    this.menuDrawerVisible = false;
  }


}

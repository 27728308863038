import { Pipe, PipeTransform, inject } from '@angular/core';
import { BooktechAppService } from "../services/booktech-app.service";


@Pipe({ name: 'sortBy', standalone: true })
export class SortByPipe implements PipeTransform {

  transform(value: any[], order = 'asc', column: string = ''): any[] {
    if (!value || order === '' || !order) { return value; } // no array
    if (value.length <= 1) { return value; } // array with only one item
    if (!column || column === '') { 
      if(order==='asc'){ return value.sort() }
      else{ return value.sort().reverse(); }
    } // sort 1d array

    return  value.sort((a: any, b: any) => {
      if (a[column] < b[column]) {
        return -1;
      } else if (a[column] > b[column]) {
        return 1;
      } else {
        return 0;
      }
    });

  }
}
import { Pipe, PipeTransform, inject } from '@angular/core';
import { BooktechAppService } from "../services/booktech-app.service";



/*
*/
@Pipe({name: 'stringify', standalone: true})
export class StringifyPipe implements PipeTransform {
  bas = inject(BooktechAppService);
  

  transform(input: any, opts:any = { }): string {
    let str = (input ? JSON.stringify(input) : "") + "";

    if (opts.pretty) {
      str = this.bas.us.pretty(str);
    }
    return str;
  }
}

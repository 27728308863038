<div nz-row [nzGutter]="g[0].gutter" [nzAlign]="opts.align || 'middle'">
  <div 
    *ngIf="cols >= 1"
    nz-col 
    [nzXs]="g[0].xs" 
    [nzSm]="g[0].sm" 
    [nzMd]="g[0].md" 
    [nzLg]="g[0].lg" 
    [nzXl]="g[0].xl" 
    [nzXXl]="g[0].xxl"
    >
    
    <ng-content select="[col0]"></ng-content>

  </div>  
  <div 
    *ngIf="cols >= 2"
    nz-col 
    [nzXs]="g[1].xs" 
    [nzSm]="g[1].sm" 
    [nzMd]="g[1].md" 
    [nzLg]="g[1].lg" 
    [nzXl]="g[1].xl" 
    [nzXXl]="g[1].xxl"
    >
    
    <ng-content select="[col1]"></ng-content>

  </div>
  <div 
    *ngIf="cols >= 3"
    nz-col 
    [nzXs]="g[2].xs" 
    [nzSm]="g[2].sm" 
    [nzMd]="g[2].md" 
    [nzLg]="g[2].lg" 
    [nzXl]="g[2].xl" 
    [nzXXl]="g[2].xxl"
    >
    
    <ng-content select="[col2]"></ng-content>

  </div>
  <div 
    *ngIf="cols >= 4"
    nz-col 
    [nzXs]="g[3].xs" 
    [nzSm]="g[3].sm" 
    [nzMd]="g[3].md" 
    [nzLg]="g[3].lg" 
    [nzXl]="g[3].xl" 
    [nzXXl]="g[3].xxl"
    >
    
    <ng-content select="[col3]"></ng-content>

  </div>
</div>

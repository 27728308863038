



<nz-layout class="content-main page-wrapper max-width-1200">
  <nz-content>

    <btlib-user-menu *ngIf="showMenu"></btlib-user-menu>
    <!-- [rolesRequired]="['SuperUser', 'Admin', 'Provider']"  -->

    <btlib-login-check *ngIf="loginCheck" ></btlib-login-check>

    <div class="" *ngIf="bas.appLogin.success">
      <bt-page-wrapper>

        <ng-content></ng-content>
      </bt-page-wrapper>
    </div>



    
  </nz-content>
 
</nz-layout>






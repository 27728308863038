import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { DATAID } from '../../../services/data.service';

import { inject } from '@angular/core';

import { LibModulesWolcModule } from '../../../components/lib-modules-wolc.module';
import { BooktechAppService } from "../../../services/booktech-app.service";


@Component({
  selector: 'btlib-login',
  standalone: true,
  imports: [ LibModulesWolcModule ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  bas = inject(BooktechAppService);
  fb = inject(UntypedFormBuilder);

  loginForm: UntypedFormGroup;
  edit:any = { }

  @Output() onLogin = new EventEmitter<any>();

  constructor( ) {
    // this.resetForm();
    this.loginForm = this.fb.group({
      username: [null, [Validators.required]],
      password: [null, [Validators.required]],
      save: [true]
    });
  }

  ngOnInit(): void {
 
  }

  resetForm() {
    this.loginForm.reset();
    this.loginForm.controls["save"].setValue(true);
  }

  lostPassword() {
    let un = this.loginForm.controls['username']; 
    un.markAsDirty();
    un.updateValueAndValidity();
    
    if (un.invalid) {
      // this.loginForm.markAsDirty();
      // this.loginForm.updateValueAndValidity();
      console.log("un.invalid: ", un);
      return;
    }

    // console.log("document.referer: " + document.referrer);
      
    let return_uri = this.bas.ds.getValue(DATAID.APP_URL_QUERY_PARAMS).parentUrl || "";
    if(this.bas.envtest) console.log("return_uri: " + return_uri);
       
    this.bas.api.lostPassword({ 
      username: un.value,
      return_uri: return_uri
    }).then(res => {

      if(this.bas.envtest) console.log("LoginComponent.lostPassword.then: ", res);

      if (!res.success) {
        
        return;
      }
      

      this.bas.ui.info(this.bas.ui.actrans("app.lib.page.login.lostPassword.success"));
      // un.setValue("");
      this.resetForm();


    }).catch(err =>  {
      if(this.bas.envtest) console.log("LoginComponent.lostPassword.err: ", err);
    })
  }

  submitForm(): void {
    let form = this.loginForm;
    for (const i in form.controls) {
      if (form.controls.hasOwnProperty(i)) {
        let control = form.controls[i];
        control.markAsDirty();
        control.updateValueAndValidity();
      }
    }

    // console.log("LoginComponent.submitForm, loginForm: ", form);

    if (form.invalid) return;

    // console.log("LoginComponent.submitForm, loginForm.values: ", form.getRawValue());

    
    this.bas.api.login(form.getRawValue()).then(res => {

      if(this.bas.envtest) console.log("LoginComponent.submitForm.then: ", res);

      if (!res.success) {
        this.loginForm.controls['password'].setValue("");
        return;
      }

      this.onLogin.next(res);

    }).catch(err =>  {
      if(this.bas.envtest) console.log("LoginComponent.submitForm.err: ", err);
    })


  }

}

import { Pipe, PipeTransform, inject } from '@angular/core';
import { BooktechAppService } from "../services/booktech-app.service";



/*

*/
@Pipe({name: 'str', standalone: true})
export class StrPipe implements PipeTransform {


  transform(input: any): string {

    return (input ? input : "") + "";
  }
}

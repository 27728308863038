import { Pipe, PipeTransform, inject } from '@angular/core';
import { BooktechAppService } from "../services/booktech-app.service";

/*
*/
@Pipe({name: 'cbtrans', standalone: true })
export class CBTransPipe implements PipeTransform {
  bas = inject(BooktechAppService);

  
  constructor() {

  }

  transform(value: any): string {
    // MiscUtil.l("value.type: " + typeof value + ", value: ", value);
    if (typeof value == "string") return value;
    if (typeof value == "object" && value.texts) {
      return this.bas.ui.cbtrans(value);
    }
    return value;
  }
}
